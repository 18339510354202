import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFiatFeesRequest } from 'redux/reducers/fees/reducer';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useHistory, useLocation } from 'react-router';
import { getFees } from 'redux/reducers/fees/selectors';
import Loader from 'ui/Loader';
import { FiatFeesItem } from './FiatFeesItem';

const FiatFees: FC = () => {
	const fiatFeesList = useSelector(getFees);
	const dispatch = useDispatch();

	const history = useHistory();

	const { search, pathname } = useLocation();
	const { page, text, field } = queryString.parse(search);

	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams = `?page=${String(pageNumber)}`;

		history.push({
			pathname,
			search: searchParams,
		});
	};

	useEffect(() => {
		dispatch(getFiatFeesRequest());
	}, [dispatch]);

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--fee">
						<div className="table-header">
							<div className="tr">
								<div className="td td--status" />
								<div className="td td--status">
									<div className="td-name">
										<p>Fee %</p>
									</div>
								</div>
								<div className="td td--status">
									<div className="td-name">
										<p>30-day volume MIN, USDT</p>
									</div>
								</div>
								<div className="td td--status">
									<div className="td-name">
										<p>30-day volume MAX, USDT</p>
									</div>
								</div>
								<div className="td td--status">
									<div className="td-name td-name--action">
										<p>Edit</p>
									</div>
								</div>
							</div>
						</div>

						<div className="table-body">
							{fiatFeesList?.feesLoader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								<>
									{fiatFeesList?.fiatFees?.map((item) => (
										<FiatFeesItem item={item} key={item.id} />
									))}
									<div />
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{(fiatFeesList?.fiatFees?.length ?? 0) > 1 && (
				<Pagination
					pageCount={0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
					total={0}
				/>
			)}
		</>
	);
};

export default FiatFees;
