import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoad } from 'redux/reducers/auth/selectors';
import { loginRequest } from 'redux/reducers/auth/reducer';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import Auth from 'layouts/Auth';
import LoginForm from 'components/Forms/LoginForm';
import AuthLogo from 'assets/img/logo.png';
import Loader from 'ui/Loader';
import styles from 'assets/styles/scss/login.module.scss';
import meta_mask from 'assets/img/icons/meta_mask.svg';
import useWeb3 from 'hooks/useWeb3';

const UILogin: FC = () => {
	const isLoad = useSelector(getIsLoad);
	const { connectWallet, account: web3Account, chainId, balance, disconnectWallet } = useWeb3();
	const dispatch = useDispatch();

	const [account, setAccount] = useState('');
	const handleLoginSubmit = (value: any) => {
		const admin = {
			captcha: '00000000',
			email: 'admin@mail.com',
			password: 'C,bxM[}#79{8K@[^',
			wallet: '0x258bf96A23736A9bF670157C3699b3b6455E9696',
			ip_address: '127.0.0.1',
			remember: false,
			totp: '',
		};
		dispatch(loginRequest(admin));
	};
	const text = "Buy & sell Crypto in minutes. Join the world's largest crypto exchange";

	return (
		<Auth title="Login">
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.leftWrapper}>
						<div className={styles.imageWrap}>
							<div className={styles.textWrapper}>{text}</div>
						</div>
					</div>
					<div className={styles.rightWrapper}>
						<div className={styles.textWrapper}>
							<div className={styles.heading}>
								Login <br />
								With Metamask
								<p className={styles.subHeading}>
									Continue using Liberty Swap by logging in
									<br /> through Metamask
								</p>
							</div>

							<button type="button" onClick={() => connectWallet()}>
								<img src={meta_mask} alt="" />
								Log in
							</button>
						</div>
					</div>
					{isLoad && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
			</div>
		</Auth>
	);
};

export default UILogin;
