import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFees } from 'redux/reducers/fees/selectors';
import { getFeesRequest } from 'redux/reducers/fees/reducer';
import Loader from 'ui/Loader';
import TradeFeesAndLimitsItem from './TradeFeesAndLimitsItem';

const TradeFeesAndLimits: FC = () => {
	const feesList = useSelector(getFees);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getFeesRequest());
	}, [dispatch]);

	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--fee-trade">
					<div className="table-header">
						<div className="tr">
							<div className="td" />
							<div className="td td--status">
								<div className="td-name">
									<p>Taker Fee, %</p>
								</div>
							</div>
							<div className="td td--status">
								<div className="td-name">
									<p>Maker Fee, %</p>
								</div>
							</div>
							<div className="td td--status">
								<div className="td-name">
									<p>30-day volume Min, USDT</p>
								</div>
							</div>
							<div className="td td--status">
								<div className="td-name">
									<p>30-day volume Max, USDT</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name td-name--action">
									<p>Edit</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-body">
						{feesList?.feesLoader ? (
							<div className="list-loader">
								<Loader />
							</div>
						) : (
							<>
								{feesList?.fees?.map((item) => (
									<TradeFeesAndLimitsItem item={item} key={item.id} />
								))}
								<div />
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeFeesAndLimits;
