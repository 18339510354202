import { CollapseIcon, ExpandIcon } from 'assets/styles/inline-svg';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { mobileMenu } from 'redux/reducers/app/reducer';
import { getMobileMenuStatus } from 'redux/reducers/app/selectors';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getAdminAddress, getAdminEmail, getIsAuth } from 'redux/reducers/auth/selectors';
import { getUserDataFromLS } from 'utils/localeStorage';
import { BurgerIcon } from 'assets/styles/inline-svg';
import useWeb3 from 'hooks/useWeb3';

// ==========================================:
const Header: FC = () => {
	const { connectWallet, account: web3Account, chainId, balance, disconnectWallet } = useWeb3();
	const ifAdminAuth = useSelector(getIsAuth);
	const adminEmail = useSelector(getAdminAddress);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleAdminLogout = (): void => {
		dispatch(logoutRequest({ history }));
		disconnectWallet();
	};

	const isMobileMenu = useSelector(getMobileMenuStatus);
	const handleMobileMenu = (): void => {
		dispatch(mobileMenu({ open: !isMobileMenu }));
	};
	const currentAdminEmail = adminEmail || getUserDataFromLS()?.email || '-----';

	return (
		<header className="header">
			<button className="sidebar__action" onClick={handleMobileMenu} type="button">
				<BurgerIcon />
			</button>
			<span className="header__title">Liberty Swap</span>
			{ifAdminAuth && (
				<div className="admin">
					<p className="admin__name ellipsis">{currentAdminEmail}</p>
					<button className="admin__logout" type="button" onClick={handleAdminLogout}>
						<svg
							className="stroke"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.1998 8.00005L11.5998 11.9M15.1998 8.00005L11.5998 4.40005M15.1998 8.00005L3.7998 8.00005M8.5998 15.2L0.799805 15.2L0.799805 0.800049L8.5998 0.800049"
								stroke="white"
							/>
						</svg>
					</button>
				</div>
			)}
		</header>
	);
};

export default Header;
