import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/walletManagement/reducer';
import { getWalletsIsLoad, getWalletsList } from 'redux/reducers/walletManagement/selectors';
import Loader from 'ui/Loader';
import { WalletItem } from './WalletItem';

export const Wallet: FC = () => {
	const walletsLoader = useSelector(getWalletsIsLoad);

	const wallets = useSelector(getWalletsList);

	const dispatch = useDispatch();

	useEffect(() => {
		const intervalId = setInterval(() => dispatch(getWalletsRequest({})), 10000);
		return () => clearInterval(intervalId);
	}, [dispatch]);

	return (
		<div className="content-block content-block--walletman">
			<div>
				<div className="table-block table-block--walletman">
					<div className="table-wrapper">
						<div className="table table--walletman">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td centerText">
										<div className="td-name">
											<p>Balance</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Deposit / Withdrawal</p>
										</div>
									</div>
								</div>
							</div>
							{!wallets?.length && !walletsLoader && (
								<div className="user-management-empty-data">
									<p className="user-management-empty__text">No wallets found...</p>
								</div>
							)}

							{!wallets?.length && walletsLoader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								<div className="table-body">
									{wallets?.map((wallet) => (
										<WalletItem key={`${wallet.asset_code}${wallet.network_id}`} data={wallet} />
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
