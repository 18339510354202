import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceList } from 'redux/reducers/balance/selectors';
import Loader from 'ui/Loader';
import {
	getBalanceListRequest,
	getHotBalanceListRequest,
	getTotalFeeCollectedListRequest,
} from 'redux/reducers/balance/reducer';
import Balance from './Balances';
import Table from './Table/Table';

// remove test data below when api is ready
const user = [
	{
		typeUser: 'registered_users',
		lastQuater: '+12678',
		lastMonth: '+1577',
		lastWeek: '+1577',
		lastDay: '+15',
	},
	{
		typeUser: 'verified_users',
		lastQuater: '+12678',
		lastMonth: '+1577',
		lastWeek: '+1577',
		lastDay: '+15',
	},
	{
		typeUser: 'active_users',
		lastQuater: '+12678',
		lastMonth: '+1577',
		lastWeek: '+1577',
		lastDay: '+15',
	},
];
const spotTrading = [
	{
		lastYear: '1.2B USDT',
		lastQuater: '230.16M USDT',
		lastMonth: '101M USDT',
		lastWeek: '201001 USDT',
		lastDay: '60093 USDT',
	},
];
const convertTrading = [
	{
		lastYear: '1.2B USDT',
		lastQuater: '230.16M USDT',
		lastMonth: '101M USDT',
		lastWeek: '201001 USDT',
		lastDay: '60093 USDT',
	},
];
const deposits = [
	{
		lastYear: '1.2B USDT',
		lastQuater: '230.16M USDT',
		lastMonth: '101M USDT',
		lastWeek: '201001 USDT',
		lastDay: '60093 USDT',
	},
];
const withdrawals = [
	{
		lastYear: '1.2B USDT',
		lastQuater: '230.16M USDT',
		lastMonth: '101M USDT',
		lastWeek: '201001 USDT',
		lastDay: '60093 USDT',
	},
];
const AdminPanel: FC = () => {
	const balanceList = useSelector(getBalanceList);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getBalanceListRequest());
		dispatch(getHotBalanceListRequest());
		dispatch(getTotalFeeCollectedListRequest());
	}, [dispatch]);

	return (
		<>
			<div className="title-block">
				<p className="title">Dashboard</p>
			</div>

			{!balanceList?.length ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<Balance data={balanceList} />
			)}
			<div>
				<div className="table_padding_admin">
					<Table heading="User" table_data={user} />
				</div>
				<div className="table_padding_admin">
					<Table heading="Volume of Spot trading" table_data={spotTrading} />
				</div>
				<div className="table_padding_admin">
					<Table heading="Volume of Convert trading" table_data={convertTrading} />
				</div>
				<div className="table_padding_admin">
					<Table heading="Volume of Deposits" table_data={deposits} />
				</div>
				<div className="table_padding_admin">
					<Table heading="Volume of withdravals" table_data={withdrawals} />
				</div>
			</div>
		</>
	);
};

export default AdminPanel;
