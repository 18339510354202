/* eslint-disable react/button-has-type */
import { FC, useState } from 'react';
import { CopyUrlModal } from './CopyUrlModal';
import { IActionsButtonProps } from './types';
import { WithdrawalModal } from './WithdrawalModal';

export const ActionsButtons: FC<IActionsButtonProps> = ({
	addressCold,
	asset_id,
	addressHot,
	network,
}) => {
	const [openCopyUrlModal, setOpenCopyUrlModal] = useState(false);
	const closeCopyUrlModal = () => setOpenCopyUrlModal(false);
	const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);
	const closeWithdrawalModal = () => setOpenWithdrawalModal(false);

	return (
		<div className="dash-actions">
			<button
				type="button"
				className="dash-actions__item"
				onClick={() => setOpenCopyUrlModal(true)}
			>
				<svg
					width="25"
					height="25"
					viewBox="0 0 25 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12.0175 24.4882C18.579 24.4882 24.0116 19.0439 24.0116 12.4941C24.0116 5.93262 18.5673 0.5 12.0058 0.5C5.45606 0.5 0.0234375 5.93262 0.0234375 12.4941C0.0234375 19.0439 5.46782 24.4882 12.0175 24.4882ZM12.0175 22.4892C6.46733 22.4892 2.03422 18.0443 2.03422 12.4941C2.03422 6.9439 6.45557 2.49902 12.0058 2.49902C17.556 2.49902 22.0127 6.9439 22.0127 12.4941C22.0127 18.0443 17.5678 22.4892 12.0175 22.4892Z"
						fill="white"
					/>
					<path
						d="M15.6864 9.57781C15.169 9.57781 14.828 9.93058 14.828 10.495V12.5293L15.0044 14.3637L13.3111 12.5528L8.98378 8.22553C8.8074 8.06091 8.59574 7.95508 8.31352 7.95508C7.79612 7.95508 7.44336 8.29608 7.44336 8.82524C7.44336 9.06041 7.56095 9.29559 7.72557 9.46022L12.0646 13.7875L13.899 15.5043L12.1587 15.3161H10.0186C9.45414 15.3161 9.08961 15.6572 9.08961 16.1746C9.08961 16.6919 9.44238 17.033 9.99505 17.033H15.5688C16.1684 17.033 16.5566 16.7625 16.5566 16.0687V10.5185C16.5566 9.96585 16.2038 9.57781 15.6864 9.57781Z"
						fill="white"
					/>
				</svg>
			</button>
			<p className="grey-color">|</p>
			<button
				type="button"
				className="dash-actions__item"
				onClick={() => setOpenWithdrawalModal(true)}
			>
				<svg
					width="24"
					height="25"
					viewBox="0 0 24 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12.0059 0.511839C5.4444 0.511839 0.0118389 5.95614 0.0118389 12.5059C0.0118389 19.0674 5.45614 24.5 12.0177 24.5C18.5674 24.5 24 19.0674 24 12.5059C24 5.95614 18.5556 0.511839 12.0059 0.511839ZM12.0059 2.51077C17.5561 2.51077 21.9892 6.95566 21.9892 12.5059C21.9892 18.0561 17.5679 22.501 12.0177 22.501C6.4674 22.501 2.01077 18.0561 2.01077 12.5059C2.01077 6.95566 6.45566 2.51077 12.0059 2.51077Z"
						fill="white"
					/>
					<path
						d="M15.7099 15.4222C16.2273 15.4222 16.5801 15.0342 16.5801 14.4815V8.93129C16.5801 8.23752 16.192 7.96699 15.5923 7.96699H10.0186C9.4659 7.96699 9.1132 8.30806 9.1132 8.82543C9.1132 9.3428 9.47764 9.68386 10.0421 9.68386H12.1823L13.9225 9.49572L12.0882 11.2125L7.74914 15.5398C7.58447 15.7044 7.46687 15.9396 7.46687 16.1748C7.46687 16.7039 7.81968 17.0449 8.33704 17.0449C8.61931 17.0449 8.84267 16.9391 9.00733 16.7745L13.3346 12.4472L15.0279 10.6363L14.8515 12.4707V14.505C14.8515 15.0694 15.1925 15.4222 15.7099 15.4222Z"
						fill="white"
					/>
				</svg>
			</button>
			<CopyUrlModal
				openModal={openCopyUrlModal}
				closeModal={closeCopyUrlModal}
				url={addressHot}
				asset_id={asset_id}
				network={network}
			/>
			<WithdrawalModal
				openModal={openWithdrawalModal}
				closeModal={closeWithdrawalModal}
				addressCold={addressCold}
				asset_id={asset_id}
				network={network}
			/>
		</div>
	);
};
