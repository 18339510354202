import { FC } from 'react';
import TableData from './Table_data';

// ==========================================:
interface TableProps {
	heading: string;
	table_data: any[]; // Change `any[]` to the type of your table data array
}
const Table: FC<TableProps> = ({ heading, table_data }) => {
	const tableHeaders = table_data.length > 0 ? Object.keys(table_data[0]) : [];
	return (
		<div className="table-block ">
			<div className="table-wrapper">
				<div className="table table--admin-table">
					<div className="table-header">
						<div className="tr-heading">
							<div className="td">
								<div className="td-name">
									<p className="td-heading td-heading--header">{heading}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-header">
						<div className="tr">
							{tableHeaders.map((header) => (
								<div className="td" key={header}>
									<div className="td-heading">
										<p>
											{header.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>

					<div className="table-body">
						{table_data?.map((item: any) => (
							<TableData key={item?.user_id} data={item} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Table;
