/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IAdminManagementListItemProps } from './types';
import { ActionsButtons } from './ActionButtons';
import copyIcon from '../../../../assets/img/copy.svg';

// ==========================================:
export const WithdrawalItem: FC<IAdminManagementListItemProps> = ({ data, handleClearSearch }) => {
	const date = new Date(data.created_at * 1000);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<Link className="td td--status " to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{data.asset_code.toUpperCase()}</p>
			</div>
			{/* <div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{data.network}</p>
			</div> */}
			<div className="td td--center-right td--relative">
				<p className="td-hidden-name">30-day volume MAX, USDT</p>
				<div className="td-volume-container">
					<span className="td-volume">202892260...8108672</span>
					<img style={{ width: 'auto' }} src={copyIcon} alt="" />
				</div>
				{/* <p>{Number(data.amount)}</p> */}
			</div>
			<div className="td td--center-right">
				<p className="td-hidden-name">Fee, %</p>
				<p>{Number(data.fee)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Created</p>
				<p>{date.toLocaleDateString('en-GB')}</p>
				<p className="timestring">{date.toLocaleTimeString()}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Message</p>
				<p className="reason-text">{data?.message?.replace(/_/g, ' ')}</p>
				{/* <div className="reason-subtitle">
					<span className="reason-subtitle__descr">{data?.message?.replace(/_/g, ' ')}</span>
				</div> */}
			</div>

			<div className="td">
				<p className="td-hidden-name">Action</p>
				<ActionsButtons id={data.id} handleClearSearch={handleClearSearch} />
			</div>
		</div>
	);
};
