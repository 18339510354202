import React, { useState } from 'react';

const Switcher: React.FC<any> = ({ isActive, setIsActive }: any) => {
	const toggleSwitch = () => {
		setIsActive(!isActive);
	};

	return (
		<div className={`switch-container ${isActive ? 'active' : ''}`} onClick={toggleSwitch}>
			{isActive && <div className="small-circle" />}
			<div className="switch-circle" />
			{!isActive && <div className="horizontal-bar" />}
		</div>
	);
};

export default Switcher;
