/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { geHotWalletAddressRequest } from 'redux/reducers/walletManagement/reducer';
import { getHotWalletAddress } from 'redux/reducers/walletManagement/selectors';
import { notificationContainer } from 'utils/notificationContainer';
import { ICopyUrlModalProps } from './types';

export const CopyUrlModal: FC<ICopyUrlModalProps> = ({
	openModal,
	closeModal,
	url,
	asset_id,
	network,
}) => {
	const urlUpload = useSelector(getHotWalletAddress)?.hot_address;
	const dispatch = useDispatch();
	const hotWallet = url || urlUpload || '';
	const [copied, setCopied] = useState(false);

	useEffect(() => {
		if (openModal) {
			dispatch(geHotWalletAddressRequest({ asset_id, network }));
		}
	}, [dispatch, openModal]);
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<button type="button" className="popup__close" onClick={closeModal}>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 3L3 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3 3L15 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<h3 className="popup-header__title">Wallet Address</h3>
						</div>
						<div className="popup-body">
							<p className="popup-text popup-text--center ">
								Use this wallet destination address to deposit crypto
							</p>
						</div>
						<div className="popup-body">
							<div className="popup-input">
								{/* <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
									<button style={{ cursor: 'default' }} type="button" className="input-copy-url">
										<svg
											width="15"
											height="15"
											viewBox="0 0 15 15"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1.32843 9.67157L0.974874 9.31802H0.974874L1.32843 9.67157ZM9.67157 1.32843L10.0251 1.68198L9.67157 1.32843ZM1.68198 10.0251L4.85355 6.85355L4.14645 6.14645L0.974874 9.31802L1.68198 10.0251ZM8.14645 10.1464L4.97487 13.318L5.68198 14.0251L8.85355 10.8536L8.14645 10.1464ZM3.32843 14C2.04247 14 1 12.9575 1 11.6716H0C0 13.5098 1.49019 15 3.32843 15V14ZM4.97487 13.318C4.53821 13.7547 3.94596 14 3.32843 14V15C4.21118 15 5.05778 14.6493 5.68198 14.0251L4.97487 13.318ZM0.974874 9.31802C0.350673 9.94222 0 10.7888 0 11.6716H1C1 11.054 1.24532 10.4618 1.68198 10.0251L0.974874 9.31802ZM13.318 4.97487L10.1464 8.14645L10.8536 8.85355L14.0251 5.68198L13.318 4.97487ZM6.85355 4.85355L10.0251 1.68198L9.31802 0.974873L6.14645 4.14645L6.85355 4.85355ZM14 3.32843C14 3.94596 13.7547 4.53821 13.318 4.97487L14.0251 5.68198C14.6493 5.05778 15 4.21118 15 3.32843H14ZM11.6716 1C12.9575 1 14 2.04247 14 3.32843H15C15 1.49019 13.5098 0 11.6716 0V1ZM11.6716 0C10.7888 0 9.94222 0.350672 9.31802 0.974873L10.0251 1.68198C10.4618 1.24532 11.054 1 11.6716 1V0ZM4.85355 10.8536L10.8536 4.85355L10.1464 4.14645L4.14645 10.1464L4.85355 10.8536Z"
												fill="#E6E8EC"
											/>
										</svg>
									</button>
								</CopyToClipboard> */}
								{/* <input className="popup-input-item" type="text" value={hotWallet} disabled /> */}
								<p className="popup-input-item--value">{hotWallet}</p>
							</div>
							<div className="popup-submit">
								<CopyToClipboard
									text={hotWallet}
									onCopy={() => {
										setCopied(true);
										notificationContainer('Hot wallet address copied', 'success');
									}}
								>
									<button
										type="button"
										className="button  button--full button--del"
										onClick={() => {
											closeModal();
										}}
									>
										Copy deposit address
									</button>
								</CopyToClipboard>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
