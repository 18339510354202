import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { authInitState, loginRequest } from 'redux/reducers/auth/reducer';
import { notificationContainer } from 'utils/notificationContainer';
import Web3 from 'web3';

const useWeb3 = () => {
	const dispatch = useDispatch();
	const [account, setAccount] = useState<string | null>(null);
	const [balance, setBalance] = useState<string | null>(null);
	const [chainId, setChainId] = useState<string | null>(null);
	const [isMetamaskInstalled, setIsMetamaskInstalled] = useState<boolean>(false);
	const history = useHistory();

	const connectWallet = async () => {
		if (window?.ethereum) {
			try {
				await window.ethereum.enable();

				const web3 = new Web3(window.ethereum);
				const accounts = await web3.eth.getAccounts();

				const admin = {
					captcha: '00000000',
					email: 'admin@mail.com',
					password: 'C,bxM[}#79{8K@[^',
					remember: false,
					wallet: '0xAB25e9a291B199FB0B48B03A72FCA6f9430a35E9',
					// wallet: accounts[0],
					// address:"0x258bf96A23736A9bF670157C3699b3b6455E9696",
					ip_address: '127.0.0.1',
					totp: '',
				};
				dispatch(loginRequest(admin));

				setAccount(accounts[0]);
				setBalance(await web3.eth.getBalance(accounts[0]));
				setChainId(await web3.eth.getChainId());

				// Add listener for account changes directly
				const accountChangeListener = () => {
					// Handle account change
					// For now, just refresh the page
					window.location.reload();
				};
				window.ethereum.on('accountsChanged', accountChangeListener);

				setIsMetamaskInstalled(true);
			} catch (error) {
				notificationContainer(error?.message, 'error');
			}
		} else {
			notificationContainer('Please install MetaMask!', 'info');
			window.open('https://metamask.io/', '_blank');
		}
	};

	const disconnectWallet = useCallback(() => {
		if (window.ethereum) {
			console.log('Disconnect Wallet');
			// Remove specific listener for account changes
			const accountChangeListener = () => {
				// Handle account change
				// For now, just refresh the page
				window.location.reload();
			};
			window.ethereum.removeListener('accountsChanged', accountChangeListener);

			// Clear state variables
			dispatch(authInitState());

			setAccount(null);
			setBalance(null);
			setChainId(null);

			// Optional: if using local storage or cookies for persistency
			localStorage.removeItem('persist:bit-d');
		}
	}, [dispatch]);

	const initWeb3 = useCallback(() => {
		if (window.ethereum) {
			setIsMetamaskInstalled(window.ethereum.isMetaMask);
		} else {
			disconnectWallet();
		}
	}, [disconnectWallet]);

	useEffect(() => {
		initWeb3();
		return () => {
			// Cleanup: remove listeners when component unmounts
			const accountChangeListener = () => {
				// Handle account change
				// For now, just refresh the page
				window.location.reload();
			};
			window.ethereum.removeListener('accountsChanged', accountChangeListener);
		};
	}, [initWeb3]);

	return {
		account,
		balance,
		chainId,
		isMetamaskInstalled,
		initWeb3,
		connectWallet,
		disconnectWallet,
	};
};

export default useWeb3;
