import React from 'react';

const WidthdrawLimitItem = () => {
	return (
		<div>
			{/* <div className="table-block"> */}
			<div className="table-wrapper">
				<div className="table table--withdraw-limits">
					<div className="table-body">
						<div className="tr">
							<div className="td td--status">
								<p className="td-hidden-name">Currency</p>
								<p>BTC/USD</p>
							</div>
							<div className="td td--status">
								<p className="td-hidden-name">Min Value</p>
								<p>5</p>
							</div>
							<div className="td td--status">
								<p className="td-hidden-name">Max Value</p>
								<p>10</p>
							</div>
							<div className="td td--status">
								<p className="td-hidden-name">Edit</p>
								<div className="table-buttons">
									<button type="button">
										<svg
											width="24"
											height="25"
											viewBox="0 0 24 25"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12.0058 24.4882C18.5673 24.4882 23.9999 19.0439 23.9999 12.4941C23.9999 5.93262 18.5556 0.5 11.9941 0.5C5.44434 0.5 0.0117188 5.93262 0.0117188 12.4941C0.0117188 19.0439 5.4561 24.4882 12.0058 24.4882ZM12.0058 22.4892C6.45562 22.4892 2.0225 18.0443 2.0225 12.4941C2.0225 6.9439 6.44386 2.49902 11.9941 2.49902C17.5443 2.49902 22.001 6.9439 22.001 12.4941C22.001 18.0443 17.5561 22.4892 12.0058 22.4892Z"
												fill="white"
											/>
											<path
												d="M8.23125 17.5858L15.9451 9.89552L14.5576 8.50796L6.85544 16.1865L6.18518 17.7505C6.0676 18.0445 6.34982 18.2914 6.60851 18.1973L8.23125 17.5858ZM16.6506 9.20174L17.4973 8.34334C17.8736 7.94354 17.9088 7.52021 17.5561 7.16744L17.2856 6.89699C16.9329 6.54423 16.4978 6.5795 16.1215 6.95578L15.2631 7.80242L16.6506 9.20174Z"
												fill="white"
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		// </div>
	);
};
export default WidthdrawLimitItem;
