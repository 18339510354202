/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getOneUser, getSelectUserIsLoad } from 'redux/reducers/userManagement/selectors';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from 'ui/Loader';
import ConvertFees from 'components/Fees/ConvertFees';
import FiatFees from 'components/Fees/FiatFees';
import FundingWallet from './FundingWallet';
import TradingWallet from './TradingWallet';

export const Account: FC = () => {
	const user = useSelector(getOneUser);
	const selectUserLoader = useSelector(getSelectUserIsLoad);

	const date = user?.birth_date ? new Date(user.birth_date).toLocaleDateString('en-GB') : '';

	return (
		<>
			{/* {!userManagement && !selectUserLoader && (
				<div className="user-management-empty-data">
					<p className="user-management-empty__text">No user found...</p>
				</div>
			)} */}

			{selectUserLoader ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<Tabs className="head-tabs">
					<TabList className="user-settings user-settings--tabsSets">
						<Tab className="user-settings__tab">
							<span className="user-settings__link">Funding Wallet</span>
						</Tab>
						<Tab className="user-settings__tab">
							<span className="user-settings__link">Trading Wallet</span>
						</Tab>
					</TabList>
					<TabPanel>
						<FundingWallet />
					</TabPanel>
					<TabPanel>
						<TradingWallet />
					</TabPanel>
				</Tabs>
			)}
		</>
	);
};
