import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import WithdrawalLimit from 'components/WithdrawalLimit';

// ================================================:
const WithdrawalLimitPage: FC = () => {
	return (
		<Dashboard title="withdrawal limit">
			<WithdrawalLimit />
		</Dashboard>
	);
};

export default WithdrawalLimitPage;
