import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAuthStore } from 'redux/reducers/auth/types';

// ==========================================:
const getAuthState = (state: IStoreState): IAuthStore => state.auth;
export const getAuth = createSelector([getAuthState], (auth: IAuthStore) => auth);

// ====================================================:
export const getIsAuth = createSelector([getAuth], (auth: IAuthStore): boolean => {
	return !!(auth.isAuthenticated && auth.accessToken);
});

// ====================================================:
export const getAuthIsAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !!(auth.isAuthenticated && auth.accessToken),
);

// ====================================================:
export const getAuthIsNotAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !auth.isAuthenticated && !auth.accessToken,
);
export const getSocketToken = createSelector(
	[getAuth],
	(auth: IAuthStore): string | null => auth.socketToken,
);
// ====================================================:
export const getAdminEmail = createSelector(
	[getAuth],
	(auth: IAuthStore): null | string => auth.email,
);

// ====================================================:
export const getAdminAddress = createSelector(
	[getAuth],
	(auth: IAuthStore): null | string => auth.wallet,
);
export const getAdminPermissions = createSelector(
	[getAuth],
	(auth: IAuthStore) => auth.userData?.permissions,
);

// ====================================================:
export const getIsLoad = createSelector([getAuth], (auth: IAuthStore): boolean => auth.loginLoader);
