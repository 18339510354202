import { FC } from 'react';

const Footer: FC = () => {
	return (
		<div className="footer">
			<div className="footer-content">
				<div className="footer-links">
					<div>
						<span>Market Overview</span>
					</div>
					<div>
						<span>Trading Fees</span>
					</div>
					<div>
						<span>Help Center</span>
					</div>
				</div>
				<div className="footer-copyright">
					<span>© 2018-2023 LibertySwap.com. All rights reserved.</span>
				</div>
			</div>
		</div>
	);
};

export default Footer;
