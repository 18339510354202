import { FC, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { withdrawToColdWalletRequest } from 'redux/reducers/walletManagement/reducer';
import { IWithdrawalModalProps } from './types';

// ================================================:
export const WithdrawalModal: FC<IWithdrawalModalProps> = ({
	openModal,
	closeModal,
	addressCold,
	asset_id,
	network,
}) => {
	const dispatch = useDispatch();
	const [amount, setAmount] = useState('');
	const [address, setAddress] = useState<string>(addressCold || '');

	const withdrawToColdWalletHandler = () => {
		closeModal();
		dispatch(
			withdrawToColdWalletRequest({
				asset_id,
				amount: Number(amount),
				address,
				network,
			}),
		);
		setAmount('');
		setAddress(addressCold || '');
	};
	const cancel = () => {
		closeModal();
		setAmount('');
		setAddress(addressCold || '');
	};
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small-medium">
						<button type="button" className="popup__close" onClick={cancel}>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 3L3 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3 3L15 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<p className="popup-header__title">Withdrawal</p>
						</div>

						<div className="popup-body">
							<p className="popup-text popup-text--center">
								Fill in the details to continue the process
							</p>
						</div>
						<div className="popup-body">
							<div className="amount-and-address">
								<div>
									<p className="black-font">Amount</p>
									<input
										className="popup-input-item popup-input-item--amount"
										type="text"
										placeholder="Amount"
										value={amount}
										onChange={(e) => setAmount(e.target.value.replace(/[^\d\\.]/g, ''))}
									/>
								</div>
								<div>
									<p className="black-font">Address</p>
									<input
										className="popup-input-item popup-input-item--amount"
										type="text"
										placeholder="Address"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
									/>
								</div>
							</div>
							<div className="popup-submit">
								{/* <button
									type="button"
									className="button button--cancel button--medium-height"
									onClick={cancel}
								>
									Cancel
								</button> */}
								<button
									type="button"
									className={`"button  button--full button--del"  ${
										!amount || Number(amount) === 0 || address?.length < 8
											? 'button-block'
											: 'button--check'
									}
									blackButton`}
									onClick={() => {
										if (!amount || Number(amount) === 0 || address?.length < 8) return;
										withdrawToColdWalletHandler();
									}}
								>
									Send
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
