import React, { FC, useEffect, useRef, useCallback } from 'react';
import Nav from 'layouts-elements/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { getMobileMenuStatus } from 'redux/reducers/app/selectors';
import { mobileMenu } from 'redux/reducers/app/reducer';

const Sidebar: FC = () => {
	const isMobileMenu = useSelector(getMobileMenuStatus);
	const sidebarRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();

	// Function to handle mobile menu state
	const handleMobileMenu = useCallback(() => {
		console.log('handleMobileMenu');
		if (window.innerWidth < 992) {
			dispatch(mobileMenu({ open: true }));
		}
	}, [dispatch]);

	// Function to handle click outside the sidebar
	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
				handleMobileMenu();
			}
		},
		[handleMobileMenu],
	);

	// Add and remove event listeners
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className="sidebar" ref={sidebarRef}>
			<Nav handleMobileMenu={handleMobileMenu} />
		</div>
	);
};

export default Sidebar;
