/* eslint-disable react-hooks/exhaustive-deps */
import SearchBar from 'components/SearchBar';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
	geHotColdTransactionsRequest,
	getWalletsRequest,
} from 'redux/reducers/walletManagement/reducer';
import { IHotColdTransactionsRequestPayload } from 'redux/reducers/walletManagement/types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { getHotColdTransactionsList } from 'redux/reducers/walletManagement/selectors';
import Pagination from 'ui/Pagination';
import { Transactions } from './Transactions';
import { Wallet } from './Wallet';

export const WalletManagement: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const [tabIndex, setTabIndex] = useState(0);

	// const hotColdTransactionsLoader = useSelector(getHotColdTransactionsIsLoad);
	const hotColdTransactions = useSelector(getHotColdTransactionsList);

	const searchFieldArray = ['id'];
	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		if (tabIndex === 1) {
			setCurrentPage(pageNumber);
			let verifyText = '';
			if (text) {
				verifyText = String(text).trim();
			}
			const searchParams =
				verifyText.length > 0
					? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
					: `?page=${String(pageNumber)}`;

			const params: IHotColdTransactionsRequestPayload =
				verifyText.length > 0
					? {
							current_page: pageNumber || 1,
							search_field: searchField,
							search_value: verifyText,
					  }
					: {
							current_page: pageNumber || 1,
					  };
			history.push({
				pathname: location.pathname,
				search: searchParams,
			});

			dispatch(geHotColdTransactionsRequest(params));
		}
	};

	const handleSearch = (txt: string) => {
		const params = {
			current_page: 1,
			search_value: txt,
			search_field: searchField,
		};

		if (txt.length >= 1) {
			dispatch(geHotColdTransactionsRequest(params));
		} else {
			dispatch(geHotColdTransactionsRequest({}));
		}
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchField}`
					: `?page=${String(1)}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 600);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		tabIndex === 1 && handleSearch(term);
	}, [term, searchField]);

	useEffect(() => {
		tabIndex === 0 && dispatch(getWalletsRequest({}));
		tabIndex === 1 && dispatch(geHotColdTransactionsRequest({}));
	}, [tabIndex]);

	const handleClearSearch = () => {
		if (tabIndex === 1) {
			setDebouncedTerm('');
			setTerm('');
		}
	};

	const searchFieldHandler = (txt: string) => {
		tabIndex === 1 && setSearchField(txt);
	};
	return (
		<>
			<div className="title-block">
				<p className="title">Wallet Management</p>
			</div>
			{tabIndex === 1 && (
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={searchFieldArray}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
				/>
			)}

			<Tabs className="head-tabs" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
				<TabList className="tabsFlex user-settings--tabsSets">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Wallet</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Transactions</span>
					</Tab>
				</TabList>
				<TabPanel>
					<Wallet />
				</TabPanel>
				<TabPanel>
					<Transactions />
				</TabPanel>
			</Tabs>

			{(hotColdTransactions?.last_page ?? 0) > 1 && tabIndex === 1 && (
				<Pagination
					pageCount={hotColdTransactions?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};
