import React from 'react';
import styles from 'assets/styles/scss/adminlogin.module.scss';
import meta_mask from 'assets/img/icons/meta_mask.svg';

const AdminLogin = () => {
	const connectWallet = () => {};
	return (
		<div className={styles.background}>
			<div className={styles.rightWrapper}>
				<div className={styles.textWrapper}>
					<div className={styles.heading}>
						Login <br />
						<p className={styles.subHeading}>
							Continue using Liberty Swap by logging in
							<br /> through Metamask
						</p>
					</div>

					<button type="button" onClick={() => connectWallet()}>
						<img src={meta_mask} alt="" className={styles.metaMaskImage} />
						Log in
					</button>
				</div>
			</div>
		</div>
	);
};

export default AdminLogin;
