import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminPermissions } from 'redux/reducers/auth/selectors';
import { getAssetsRequest } from 'redux/reducers/tradingPairs/reducer';
import { AddNewPairModal } from './AddNewPairModal';
import { SpotPairs } from './SpotPairs';

export const TradingPairs: FC = () => {
	const dispatch = useDispatch();

	const [openModal, setOpenModal] = useState(false);
	const permissions = useSelector(getAdminPermissions);

	const manageAssetPairs =
		permissions?.find((permission: any) => permission.name === 'manage derivatives') || null;

	useEffect(() => {
		if (manageAssetPairs) {
			dispatch(getAssetsRequest());
		}
	}, [dispatch, manageAssetPairs]);

	return (
		<div className="trading-pairs">
			<div className="title-block title-block--admin-managment">
				<p className="title">Trading Pairs</p>

				<div className="admin-managment-btn" onClick={() => setOpenModal(true)}>
					<span className="admin-managment-btn__text">Add New Pair</span>
					<span className="plus-icon plus-icon--action icon-plus-icon" />
				</div>
			</div>

			<div className="trading-pairs-body">
				<SpotPairs />
			</div>

			<AddNewPairModal openModal={openModal} setCloseModal={() => setOpenModal(false)} />
		</div>
	);
};
