import React from 'react';
import WidthdrawLimitItem from './WithdrawLimitItem/WidthdrawLimitItem';

const List = () => {
	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--withdraw-limits">
					<div className="table-header">
						<div className="tr">
							<div className="td td--status">
								<div className="td-name">
									<p>Currency</p>
								</div>
							</div>

							<div className="td td--status">
								<div className="td-name">
									<p>Min Value</p>
								</div>
							</div>

							<div className="td td--status">
								<div className="td-name">
									<p>Max Value</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name">
									<p>Edit</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<WidthdrawLimitItem />
			</div>
		</div>
	);
};

export default List;
