/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// import SearchBar from 'components/SearchBar';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editNotificationsRequest } from 'redux/reducers/notifications/reducer';
import Switcher from 'components/Switcher';
import { IChangeNotificationValue, INotificationItemProps } from './types';

// ==========================================:
const NotificationsItem: FC<INotificationItemProps> = ({ item }) => {
	const [smsSwitcher, setSmsSwitcher] = useState(item.sms_enabled);
	const [emailSwitcher, setEmailSwitcher] = useState<any>(item.email_enabled || false);
	// const [pushUpSwitcher, setPushUpSwitcher] = useState(false);

	const dispatch = useDispatch();

	const handleNotificationChange = (value: IChangeNotificationValue) => {
		dispatch(editNotificationsRequest(value));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Notifications</p>
				<p>{`${item.name[0].toUpperCase()}${item.name.slice(1)}`}</p>
			</div>
			{/* <div className="td td--center">
				<p className="td-hidden-name">SMS</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={smsSwitcher}
							onChange={(e) => {
								setSmsSwitcher(e.target.checked);
								handleNotificationChange({
									id: item.id,
									sms_enabled: !smsSwitcher,
									email_enabled: emailSwitcher,
								});
							}}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div> */}
			<div className="td td--right">
				<p className="td-hidden-name">Email</p>
				<Switcher isActive={emailSwitcher} setIsActive={setEmailSwitcher} />
			</div>
		</div>
	);
};

export default NotificationsItem;
