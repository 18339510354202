import React from 'react';
import { dummyData } from './constants';

const TransactionHistoryUI = () => {
	return (
		<div>
			<div
			// 	className="
			// // content-block__inside content-block__inside--bt content-block__inside--login-his"
			>
				<div className="table-block table-block--ip">
					<div className="table-wrapper">
						<div className="table table--login-his ">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>ID</p>
										</div>
									</div>

									<div className="td">
										<div className="td-name">
											<p>User ID</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Pair</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name ">
											<p>Pair</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name ">
											<p>Value</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name ">
											<p>Fee</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name ">
											<p>Status</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td--right">
											<p>Created</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{dummyData &&
									dummyData.map((data: any) => {
										// Assuming dateOffset is a property of each data item
										// const dateOffset = new Date(data.dateOffset);

										return (
											<div className="tr" key={data.id}>
												{' '}
												{/* Assuming each data item has a unique id */}
												<div className="td">
													<p className="td-hidden-name">ID</p>
													<p>{data.id}</p>
												</div>
												<div className="td">
													<p className="td-hidden-name">User ID</p>
													<p>{data.user_id}</p>
												</div>
												<div className="td">
													<p className="td-hidden-name">Pair</p>
													<p>{data.pair}</p>
												</div>
												<div className="td">
													<p className="td-hidden-name">Pair</p>
													<p>{data.pair_name}</p>
												</div>
												<div className="td">
													<p className="td-hidden-name">Value</p>
													<p>{data.value}</p>
												</div>
												<div className="td">
													<p className="td-hidden-name">Fee</p>
													<p>{data.fee}</p>
												</div>
												<div className="td">
													<p className="td-hidden-name">Status</p>
													<p
														className={data.status === 'Confirmed' ? 'status-green' : 'status-red'}
													>
														{data.status}
													</p>
												</div>
												<div className="td">
													<p className="td-hidden-name">Created</p>
													<p>{data.created}</p>
												</div>
												{/* <div className="td td--right">
													<p className="td-hidden-name">Last Login</p>
													<p>
														{dateOffset.toLocaleDateString('en-GB')}
														{dateOffset.toLocaleTimeString()}
													</p>
												</div> */}
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransactionHistoryUI;
