import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';

import { ISpotBalancesProps } from './types';

const SpotBalancesItem: FC<ISpotBalancesProps> = ({ item }) => {
	const [cryptocurrency] = useState(item.asset.code);
	const [totalBalance] = useState(item.total);
	const [availableBalance] = useState(item.balance);
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Cryptocurrency</p>
				<p style={{ textTransform: 'uppercase' }}>{cryptocurrency}</p>
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Total balance</p>
				<p>{noExponent(Number(totalBalance).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Available balance</p>
				<p>{noExponent(Number(availableBalance).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Edit</p>
				<p>
					<svg
						width="24"
						height="25"
						viewBox="0 0 24 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_1_24975)">
							<path
								d="M12.0058 24.4882C18.5673 24.4882 23.9999 19.0439 23.9999 12.4941C23.9999 5.93262 18.5556 0.5 11.9941 0.5C5.44434 0.5 0.0117188 5.93262 0.0117188 12.4941C0.0117188 19.0439 5.4561 24.4882 12.0058 24.4882ZM12.0058 22.4892C6.45562 22.4892 2.0225 18.0443 2.0225 12.4941C2.0225 6.9439 6.44386 2.49902 11.9941 2.49902C17.5443 2.49902 22.001 6.9439 22.001 12.4941C22.001 18.0443 17.5561 22.4892 12.0058 22.4892Z"
								fill="white"
							/>
							<path
								d="M8.23125 17.5853L15.9451 9.89503L14.5576 8.50748L6.85544 16.186L6.18518 17.75C6.0676 18.044 6.34982 18.2909 6.60851 18.1968L8.23125 17.5853ZM16.6506 9.20126L17.4973 8.34285C17.8736 7.94305 17.9088 7.51972 17.5561 7.16696L17.2856 6.8965C16.9329 6.54374 16.4978 6.57901 16.1215 6.95529L15.2631 7.80194L16.6506 9.20126Z"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1_24975">
								<rect
									width="23.9882"
									height="24"
									fill="white"
									transform="translate(0.0117188 0.5)"
								/>
							</clipPath>
						</defs>
					</svg>
				</p>
			</div>
		</div>
	);
};
export default SpotBalancesItem;
