import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Anouncements from 'components/Anouncements';

// ================================================:
const AnouncementsPage: FC = () => {
	return (
		<Dashboard title="withdrawal limit">
			<Anouncements />
		</Dashboard>
	);
};

export default AnouncementsPage;
