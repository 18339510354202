/* eslint-disable no-nested-ternary */

import { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	TRANSACTION_TYPE_DEPOSIT,
	TRANSACTION_TYPE_DEPOSIT_TEXT,
	TRANSACTION_TYPE_WITHDRAWAL,
	TRANSACTION_TYPE_WITHDRAWAL_TEXT,
} from 'redux/reducers/walletManagement/constants';
import { notificationContainer } from 'utils/notificationContainer';
import { ITransactionsItemParams } from './types';

export const TransactionsItem: FC<ITransactionsItemParams> = ({ data }) => {
	const dateCreated = new Date(data.created_at).toLocaleString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: '2-digit',
	});

	const type =
		data.type === TRANSACTION_TYPE_WITHDRAWAL
			? TRANSACTION_TYPE_WITHDRAWAL_TEXT
			: data.type === TRANSACTION_TYPE_DEPOSIT
			? TRANSACTION_TYPE_DEPOSIT_TEXT
			: '';

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">ID</p>
					<p>{data.id}</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Date</p>
					<p style={{ whiteSpace: 'nowrap' }}>{dateCreated}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Coin</p>
					<p>
						{data.code.toUpperCase()}{' '}
						{/* {data.network && data.network !== data.code && <>({data.network.toUpperCase()})</>} */}
					</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Amount</p>
					<p>{data.amount}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p>{type}</p>
				</div>
				<div className="td wallet-transaction">
					<p className="td-hidden-name">Address to</p>
					<p className="transaction-address">{data.address_to}</p>
					<CopyToClipboard
						text={data.address_from}
						onCopy={() => {
							notificationContainer('Address to copied', 'success');
						}}
					>
						<button type="button" className="">
							<svg
								width="30"
								height="31"
								viewBox="0 0 30 31"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect y="0.5" width="30" height="30" rx="15" fill="#27272B" />
								<g clipPath="url(#clip0_1_32240)">
									<path
										d="M11.9333 10.2451V17.9649C11.9333 19.1264 12.521 19.71 13.7019 19.71H19.0646C20.2398 19.71 20.8331 19.1209 20.8331 17.9649V13.452C20.8331 12.7684 20.6905 12.3349 20.2626 11.9069L17.3816 9.05578C16.9766 8.65006 16.4973 8.5 15.8869 8.5H13.7019C12.5267 8.5 11.9333 9.08912 11.9333 10.2451ZM12.8519 17.9482V10.2618C12.8519 9.71159 13.1542 9.3948 13.7475 9.3948H16.2691V12.2459C16.2691 12.8906 16.5886 13.1963 17.2447 13.1963H19.9146V17.9482C19.9146 18.504 19.6065 18.8152 19.0133 18.8152H13.7418C13.1542 18.8152 12.8519 18.504 12.8519 17.9482ZM17.0964 12.1125V9.83386L19.675 12.3904H17.3759C17.1762 12.3904 17.0964 12.3071 17.0964 12.1125Z"
										fill="#7A7A7A"
									/>
									<path
										d="M9.16675 20.7383C9.16675 21.8999 9.75436 22.4835 10.9353 22.4835H16.298C17.4789 22.4835 18.0665 21.8943 18.0665 20.7383V16.3977C18.0665 15.6863 17.981 15.3751 17.5246 14.9194L14.3811 11.8014C13.9475 11.3679 13.5881 11.2734 12.9492 11.2734H10.9353C9.76007 11.2734 9.16675 11.857 9.16675 13.0186V20.7383Z"
										fill="#27272B"
									/>
									<path
										d="M9.16675 20.7383C9.16675 21.8999 9.75436 22.4835 10.9353 22.4835H16.298C17.4789 22.4835 18.0665 21.8943 18.0665 20.7383V16.3977C18.0665 15.6863 17.981 15.3751 17.5246 14.9194L14.3811 11.8014C13.9475 11.3679 13.5881 11.2734 12.9492 11.2734H10.9353C9.76007 11.2734 9.16675 11.857 9.16675 13.0186V20.7383ZM10.0853 20.7216V13.0297C10.0853 12.485 10.3876 12.1682 10.9809 12.1682H12.8522V15.3917C12.8522 16.092 13.2173 16.4422 13.9247 16.4422H17.148V20.7216C17.148 21.2774 16.8399 21.5887 16.2523 21.5887H10.9752C10.3876 21.5887 10.0853 21.2774 10.0853 20.7216ZM14.0331 15.603C13.8049 15.603 13.7136 15.514 13.7136 15.2917V12.3739L16.9711 15.603H14.0331Z"
										fill="#7A7A7A"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1_32240">
										<rect
											width="11.6667"
											height="14"
											fill="white"
											transform="translate(9.16675 8.5)"
										/>
									</clipPath>
								</defs>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
				<div className="td wallet-transaction">
					<p className="td-hidden-name">Hash</p>
					<p className="transaction-hash">{data.transaction_hash}</p>
					<CopyToClipboard
						text={data.address_from}
						onCopy={() => {
							notificationContainer('Hash copied', 'success');
						}}
					>
						<button type="button" className="">
							<svg
								width="30"
								height="31"
								viewBox="0 0 30 31"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect y="0.5" width="30" height="30" rx="15" fill="#27272B" />
								<g clipPath="url(#clip0_1_32240)">
									<path
										d="M11.9333 10.2451V17.9649C11.9333 19.1264 12.521 19.71 13.7019 19.71H19.0646C20.2398 19.71 20.8331 19.1209 20.8331 17.9649V13.452C20.8331 12.7684 20.6905 12.3349 20.2626 11.9069L17.3816 9.05578C16.9766 8.65006 16.4973 8.5 15.8869 8.5H13.7019C12.5267 8.5 11.9333 9.08912 11.9333 10.2451ZM12.8519 17.9482V10.2618C12.8519 9.71159 13.1542 9.3948 13.7475 9.3948H16.2691V12.2459C16.2691 12.8906 16.5886 13.1963 17.2447 13.1963H19.9146V17.9482C19.9146 18.504 19.6065 18.8152 19.0133 18.8152H13.7418C13.1542 18.8152 12.8519 18.504 12.8519 17.9482ZM17.0964 12.1125V9.83386L19.675 12.3904H17.3759C17.1762 12.3904 17.0964 12.3071 17.0964 12.1125Z"
										fill="#7A7A7A"
									/>
									<path
										d="M9.16675 20.7383C9.16675 21.8999 9.75436 22.4835 10.9353 22.4835H16.298C17.4789 22.4835 18.0665 21.8943 18.0665 20.7383V16.3977C18.0665 15.6863 17.981 15.3751 17.5246 14.9194L14.3811 11.8014C13.9475 11.3679 13.5881 11.2734 12.9492 11.2734H10.9353C9.76007 11.2734 9.16675 11.857 9.16675 13.0186V20.7383Z"
										fill="#27272B"
									/>
									<path
										d="M9.16675 20.7383C9.16675 21.8999 9.75436 22.4835 10.9353 22.4835H16.298C17.4789 22.4835 18.0665 21.8943 18.0665 20.7383V16.3977C18.0665 15.6863 17.981 15.3751 17.5246 14.9194L14.3811 11.8014C13.9475 11.3679 13.5881 11.2734 12.9492 11.2734H10.9353C9.76007 11.2734 9.16675 11.857 9.16675 13.0186V20.7383ZM10.0853 20.7216V13.0297C10.0853 12.485 10.3876 12.1682 10.9809 12.1682H12.8522V15.3917C12.8522 16.092 13.2173 16.4422 13.9247 16.4422H17.148V20.7216C17.148 21.2774 16.8399 21.5887 16.2523 21.5887H10.9752C10.3876 21.5887 10.0853 21.2774 10.0853 20.7216ZM14.0331 15.603C13.8049 15.603 13.7136 15.514 13.7136 15.2917V12.3739L16.9711 15.603H14.0331Z"
										fill="#7A7A7A"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1_32240">
										<rect
											width="11.6667"
											height="14"
											fill="white"
											transform="translate(9.16675 8.5)"
										/>
									</clipPath>
								</defs>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
				<div className="td wallet-transaction">
					<p className="td-hidden-name">Address from</p>
					<p className="transaction-address">{data.address_from}</p>
					<CopyToClipboard
						text={data.address_from}
						onCopy={() => {
							notificationContainer('Address from copied', 'success');
						}}
					>
						<button type="button" className="">
							<svg
								width="30"
								height="31"
								viewBox="0 0 30 31"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect y="0.5" width="30" height="30" rx="15" fill="#27272B" />
								<g clipPath="url(#clip0_1_32240)">
									<path
										d="M11.9333 10.2451V17.9649C11.9333 19.1264 12.521 19.71 13.7019 19.71H19.0646C20.2398 19.71 20.8331 19.1209 20.8331 17.9649V13.452C20.8331 12.7684 20.6905 12.3349 20.2626 11.9069L17.3816 9.05578C16.9766 8.65006 16.4973 8.5 15.8869 8.5H13.7019C12.5267 8.5 11.9333 9.08912 11.9333 10.2451ZM12.8519 17.9482V10.2618C12.8519 9.71159 13.1542 9.3948 13.7475 9.3948H16.2691V12.2459C16.2691 12.8906 16.5886 13.1963 17.2447 13.1963H19.9146V17.9482C19.9146 18.504 19.6065 18.8152 19.0133 18.8152H13.7418C13.1542 18.8152 12.8519 18.504 12.8519 17.9482ZM17.0964 12.1125V9.83386L19.675 12.3904H17.3759C17.1762 12.3904 17.0964 12.3071 17.0964 12.1125Z"
										fill="#7A7A7A"
									/>
									<path
										d="M9.16675 20.7383C9.16675 21.8999 9.75436 22.4835 10.9353 22.4835H16.298C17.4789 22.4835 18.0665 21.8943 18.0665 20.7383V16.3977C18.0665 15.6863 17.981 15.3751 17.5246 14.9194L14.3811 11.8014C13.9475 11.3679 13.5881 11.2734 12.9492 11.2734H10.9353C9.76007 11.2734 9.16675 11.857 9.16675 13.0186V20.7383Z"
										fill="#27272B"
									/>
									<path
										d="M9.16675 20.7383C9.16675 21.8999 9.75436 22.4835 10.9353 22.4835H16.298C17.4789 22.4835 18.0665 21.8943 18.0665 20.7383V16.3977C18.0665 15.6863 17.981 15.3751 17.5246 14.9194L14.3811 11.8014C13.9475 11.3679 13.5881 11.2734 12.9492 11.2734H10.9353C9.76007 11.2734 9.16675 11.857 9.16675 13.0186V20.7383ZM10.0853 20.7216V13.0297C10.0853 12.485 10.3876 12.1682 10.9809 12.1682H12.8522V15.3917C12.8522 16.092 13.2173 16.4422 13.9247 16.4422H17.148V20.7216C17.148 21.2774 16.8399 21.5887 16.2523 21.5887H10.9752C10.3876 21.5887 10.0853 21.2774 10.0853 20.7216ZM14.0331 15.603C13.8049 15.603 13.7136 15.514 13.7136 15.2917V12.3739L16.9711 15.603H14.0331Z"
										fill="#7A7A7A"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1_32240">
										<rect
											width="11.6667"
											height="14"
											fill="white"
											transform="translate(9.16675 8.5)"
										/>
									</clipPath>
								</defs>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
		</div>
	);
};
