import { FC } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setUserBlockUnblockRequest } from 'redux/reducers/userManagement/reducer';
import { IUserBlockUnblockRequestData } from 'redux/reducers/userManagement/types';
import { IBlockedButtonProps } from '../BlockedButton/types';

export const UIBlockedButton: FC<IBlockedButtonProps> = ({ blocked, id, handleClearSearch }) => {
	const dispatch = useDispatch();

	const toggleUserBlock = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		handleClearSearch();

		const payload: IUserBlockUnblockRequestData = {
			user_id: id,
			blocked: blocked ? 0 : 1,
		};
		dispatch(setUserBlockUnblockRequest(payload));
	};

	return (
		<div className="table-buttons">
			<button
				type="button"
				className={classNames({ lock: blocked })}
				disabled={blocked}
				onClick={toggleUserBlock}
			>
				<svg
					width="17"
					height="17"
					viewBox="0 0 12 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2.57371 16.0792H9.83923C11.0064 16.0792 11.6258 15.4439 11.6258 14.1893V8.71835C11.6258 7.46374 11.0064 6.83647 9.83923 6.83647H2.57371C1.40646 6.83647 0.787109 7.46374 0.787109 8.71835V14.1893C0.787109 15.4439 1.40646 16.0792 2.57371 16.0792ZM2.17669 7.44788H3.43921V4.76402C3.43921 2.76302 4.71763 1.69901 6.20249 1.69901C7.68736 1.69901 8.98168 2.76302 8.98168 4.76402V7.44788H10.2362V4.9387C10.2362 1.9531 8.28289 0.5 6.20249 0.5C4.13003 0.5 2.17669 1.9531 2.17669 4.9387V7.44788Z"
						fill={blocked ? 'white' : 'grey'}
					/>
				</svg>
			</button>

			<button type="button" className="active" disabled={!blocked} onClick={toggleUserBlock}>
				<svg
					width="17"
					height="17"
					viewBox="0 0 17 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2.41613 16.0943H9.69611C10.8656 16.0943 11.4862 15.4577 11.4862 14.2007V8.71882C11.4862 7.46965 10.8656 6.83317 9.69611 6.83317H2.41613C1.24656 6.83317 0.625977 7.46965 0.625977 8.71882V14.2007C0.625977 15.4577 1.24656 16.0943 2.41613 16.0943ZM8.92437 7.45376H10.1814V4.7725C10.1814 2.75957 11.4704 1.70139 12.9582 1.70139C14.446 1.70139 15.7349 2.75957 15.7349 4.7725V6.53879C15.7349 7.00821 16.0134 7.24689 16.3714 7.24689C16.7135 7.24689 16.9999 7.03207 16.9999 6.53879V4.93958C16.9999 1.94803 15.0348 0.5 12.9582 0.5C10.8736 0.5 8.92437 1.94803 8.92437 4.93958V7.45376Z"
						fill={blocked ? 'grey' : 'white'}
					/>
				</svg>
			</button>
		</div>
	);
};
