import React from 'react';
import { dummyData } from './constants';
import FundingWalletList from './FundingWalletList';

const FundingWallet = () => {
	const coins = dummyData;

	return (
		<div>
			<FundingWalletList coins={coins} />
		</div>
	);
};

export default FundingWallet;
