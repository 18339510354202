/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, MouseEvent } from 'react';
import { numberFormat } from 'utils/numberFormat';
import Chart from '../Chart';
import { IBalancesProps } from './types';

const Balances: FC<IBalancesProps> = ({ data }) => {
	const [balanceTab, setBalanceTab] = useState<string>(data?.[0]?.asset_code);

	// let totalBalance = 0;
	// data.forEach((item) => {
	// 	totalBalance += item.balances[0].balance_usdt;
	// });

	const handleBalanceTab = (e: MouseEvent<HTMLButtonElement>): void => {
		setBalanceTab(e.currentTarget.name);
	};

	// const charactersAfterZero = process.env.REACT_APP_NAME_CHARACTERS_AFTER_ZERO;

	return (
		<div className="content-block">
			<div className="content-block__inside">
				<div className="stats d-flex flex-column flex-md-row">
					<div className="">
						<Chart data={data} currentBalanceItemName={balanceTab} />
					</div>

					<div className="">
						<div className="stats__info">
							<div className="stats__header">
								<span className="stats__title">Balance</span>
							</div>

							<div className="stats__list">
								{data?.length
									? data.map((el) => (
											<button
												key={el.asset_code}
												name={el.asset_code}
												type="button"
												className={`stats-item ${
													balanceTab === el.asset_code ? 'stats-item--active' : ''
												}`}
												onClick={handleBalanceTab}
											>
												<div className="d-flex flex-column">
													<div className="stats-item__name">
														<span className="stats-item__img">
															{/* remove replacing below when api is ready */}
															<img
																src={el.asset_logo_url.replace('1exbuild', 'libertyswap')}
																alt={el.asset_name}
															/>
														</span>
														<span className="stats-item__title">{el.asset_code.toUpperCase()}</span>
													</div>
													<div className="stats-item__desc">
														{el.balances?.length
															? el.balances.map((item: any) => (
																	<span className="stats-item__elem" key={item.balance}>
																		{`${numberFormat(item.balance, 'en-EN')} / $
													${numberFormat(item.balance_usdt, 'en-EN')}`}
																	</span>
															  ))
															: null}
													</div>
												</div>
											</button>
									  ))
									: null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Balances;
