export const dummyData = [
	{
		asset_id: 1,
		asset_name: 'Solana',
		asset_logo_url: 'https://cryptologos.cc/logos/solana-sol-logo.png?v=014',
		asset_code: 'SOL',
		total: 150,
		available: 140,
		in_order: 10,
		btc_value: 0.03,
		btc_equivalent: 0.03,
	},
	{
		asset_id: 2,
		asset_name: 'Cardano',
		asset_logo_url: 'https://cryptologos.cc/logos/cardano-ada-logo.png?v=014',
		asset_code: 'ADA',
		total: 3000,
		available: 2900,
		in_order: 100,
		btc_value: 0.04,
		btc_equivalent: 0.04,
	},
	{
		asset_id: 3,
		asset_name: 'Polygon',
		asset_logo_url: 'https://cryptologos.cc/logos/polygon-matic-logo.png?v=014',
		asset_code: 'MATIC',
		total: 2000,
		available: 1900,
		in_order: 100,
		btc_value: 0.02,
		btc_equivalent: 0.02,
	},
	{
		asset_id: 4,
		asset_name: 'Ethereum',
		asset_logo_url: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=014',
		asset_code: 'ETH',
		total: 10,
		available: 8,
		in_order: 2,
		btc_value: 0.5,
		btc_equivalent: 0.5,
	},
];
