/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { ITransfersItemProps } from './types';

export const TransfersItem: FC<ITransfersItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data?.id}</p>
			</div>
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td td--status">
				<p className="td-hidden-name">Time</p>
				<p>{dateOffset.toLocaleDateString('en-GB')}</p>
				<p className="copy-button__color"> {dateOffset.toLocaleTimeString()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Assert</p>
				<p>{data?.asset?.code?.toUpperCase() ?? ''}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From wallet</p>
				<p>
					{data.from?.[0]?.toUpperCase()}
					{data.from?.slice(1)}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">To wallet</p>
				<p>
					{data.to?.[0]?.toUpperCase()}
					{data.to?.slice(1)}
				</p>
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(data.amount)}</p>
			</div>
		</div>
	);
};
