import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { getMobileMenuStatus } from 'redux/reducers/app/selectors';
import Header from 'layouts-elements/Header';
import Sidebar from 'layouts-elements/Sidebar';
import { mobileMenu } from 'redux/reducers/app/reducer';
import { IDashboard } from './types';
import Footer from '../../layouts-elements/footer';
// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	const mobileMenuStatus = useSelector(getMobileMenuStatus);
	const dispatch = useDispatch();

	useEffect(() => {
		// let lastInnerWidth = window.innerWidth;
		const handleResize = () => {
			if (window.innerWidth <= 2800 && window.innerWidth >= 992) {
				dispatch(mobileMenu({ open: false }));
			} else {
				dispatch(mobileMenu({ open: true }));
			}
			// lastInnerWidth = window.innerWidth;
		};

		handleResize();
		// Event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup function
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch]);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className="wrapper dark">
				<div className="dark:bg-main-dark-bg">
					<Header />
					<main className="main">
						<section className={`main-section ${mobileMenuStatus ? 'minimized' : ''}`}>
							<Sidebar />
							<div className="main-content">{children}</div>
						</section>
						<Footer />
					</main>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
