import { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import TradeFeesAndLimits from './TradeFeesAndLimits';
import CryptoFees from './CryptoFees';
import FiatFees from './FiatFees';
import PlatformFees from './PlatformFees';
import VolumeDependsFeeSettings from './VolumeDependsFeeSettings/VolumeDependsFeeSettings';
import MarginBorrow from './MarginBorrow';
import ConvertFees from './ConvertFees';
import WithdrawlFees from './WithdrawlFees';

const Fees: FC = () => {
	return (
		<>
			<div className="title-block">
				<p className="title">Fee Management</p>
			</div>
			<Tabs className="head-tabs">
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Convert</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Deposit</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Withdrawls</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Spot Trading</span>
					</Tab>
					{/* HIDE NON-WORKING */}
					{/* <Tab className="user-settings__item">
						<span className="user-settings__link">Volume depends fee settings</span>
					</Tab> */}
					{/* <Tab className="user-settings__item">
						<span className="user-settings__link">Margin borrow</span>
					</Tab> */}
				</TabList>
				<TabPanel>
					<ConvertFees />
				</TabPanel>
				<TabPanel>
					<FiatFees />
				</TabPanel>
				<TabPanel>
					<WithdrawlFees />
				</TabPanel>
				<TabPanel>
					<TradeFeesAndLimits />
				</TabPanel>
				{/* HIDE NON-WORKING */}
				{/* <TabPanel>
					<VolumeDependsFeeSettings />
				</TabPanel> 
				<TabPanel>
					<MarginBorrow />
				</TabPanel>
				*/}
			</Tabs>
		</>
	);
};

export default Fees;
