import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { IPagination, IHandlePage } from './types';

const Pagination: FC<IPagination> = (props) => {
	const { pageCount, forcePage, onPageChange, type, total } = props;
	const history = useHistory();
	const { search } = useLocation();
	const { page, action, crc, startDate, endDate, text } = queryString.parse(search);

	const handlePage = (propsValue: IHandlePage) => {
		const { selected } = propsValue;

		const actionParam = `&action=${String(action)}`;
		const currencyParam = `&crc=${String(crc)}`;
		const startDateParam = `&startDate=${String(startDate)}`;
		const endDateParam = `&endDate=${String(endDate)}`;

		const textParam = Number(text?.length) >= 3 ? `&text=${String(text)}` : '';

		const param = `${(action && actionParam) || ''}${(crc && currencyParam) || ''}${
			(startDate && startDateParam) || ''
		}${(endDate && endDateParam) || ''}${(text && textParam) || ''}`;

		if (type === 'user-transaction') {
			history.push(`?page=${Number(page)}&userpage=${Number(selected) + 1}${param}`);
		} else {
			history.push(`?page=${Number(selected) + 1}${param}`);
		}

		return onPageChange(Number(selected) + 1);
	};

	return (
		<div className="pagination-block">
			{total && <p style={{ marginTop: '0.9rem' }}>Total {total} items</p>}
			<ReactPaginate
				pageCount={pageCount}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
				containerClassName="pagination"
				previousLabel={
					<svg
						width="6"
						height="10"
						viewBox="0 0 6 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M5.5 0L5.5 9V10L0.5 5L5.5 0Z" fill="white" />
					</svg>
				}
				nextLabel={
					<svg
						width="6"
						height="10"
						viewBox="0 0 6 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0.5 0L0.5 9L0.5 10L5.5 5L0.5 0Z" fill="white" />
					</svg>
				}
				nextLinkClassName="arrow"
				previousLinkClassName="arrow"
				activeLinkClassName="active"
				disabledLinkClassName="disabled"
				forcePage={forcePage}
				onPageChange={handlePage}
			/>
		</div>
	);
};

export default Pagination;
