import classNames from 'classnames';
import { FC, useState } from 'react';

import { noExponent } from 'utils/numberFormat';
import { orderStatusClassNames, orderStatusNames } from '../../utils';
import { IOrdersHistoryProps } from './types';

const SpotOrderHistory: FC<IOrdersHistoryProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [type] = useState(item.type);
	const [amount] = useState(item.quantity);
	const [total] = useState(item.total);
	const [price] = useState(item.price);
	const [created] = useState(item.created_at);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				{id}
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Pair</p>
				<p style={{ textTransform: 'uppercase' }}>{item.asset_pair.code.replace(' ', '/')}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Type</p>
				<p className={classNames('status', orderStatusClassNames[item.status])}>
					{type.replace('_', '/')}
				</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Filling</p>
				<p>{item.filled}%</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Total</p>
				<p>{noExponent(Number(total).toString())}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Price</p>
				<p>{price !== null ? noExponent(Number(price).toString()) : '-'}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={classNames('status', orderStatusClassNames[item.status])}>
						{orderStatusNames[item.status]}
					</span>
				</div>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Created</p>
				<p>{dateOffset.toLocaleDateString('en-GB')}</p>
			</div>
		</div>
	);
};

export default SpotOrderHistory;
