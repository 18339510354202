import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Notifications from 'components/Notifications';

// ================================================:
const NotificationPage: FC = () => {
	return (
		<Dashboard title="Notification">
			<Notifications />
		</Dashboard>
	);
};

export default NotificationPage;
