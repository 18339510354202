export const whitelistDummyData = {
	data: [
		{
			id: 1,
			user_id: 101,
			ip: '192.168.1.100',
			verified: 1,
			created_at: '2024-05-24T08:00:00Z',
		},
		{
			id: 2,
			user_id: 102,
			ip: '10.0.0.1',
			verified: 0,
			created_at: '2024-05-23T15:30:00Z',
		},
		{
			id: 3,
			user_id: 103,
			ip: '172.16.0.5',
			verified: 1,
			created_at: '2024-05-22T12:45:00Z',
		},
		{
			id: 4,
			user_id: 104,
			ip: '192.0.2.1',
			verified: 1,
			created_at: '2024-05-21T10:20:00Z',
		},
		{
			id: 5,
			user_id: 105,
			ip: '198.51.100.5',
			verified: 0,
			created_at: '2024-05-20T09:10:00Z',
		},
	],
	last_page: 2,
};
