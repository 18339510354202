/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getWithdrawalsList } from 'redux/reducers/withdrawals/selectors';
import { IWithdrawalsListRequestPayload } from 'redux/reducers/withdrawals/types';
import { getWithdrawalsRequest } from 'redux/reducers/withdrawals/reducer';
import WithdrawalList from './WithdrawalList';
import SearchBar from '../SearchBar';

// ==========================================:
const Withdrawals: FC = () => {
	const withdrawals = useSelector(getWithdrawalsList);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = location;

	const searchFieldArray = ['user_id'];
	const searchFieldTextArray = ['User ID'];

	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const params: IWithdrawalsListRequestPayload =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
				  }
				: {
						current_page: pageNumber || 1,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getWithdrawalsRequest(params));
	};

	const handleSearch = (txt: string) => {
		const params = {
			current_page: 1,
			search_value: txt,
			search_field: searchField,
		};

		if (txt.length >= 1) {
			dispatch(getWithdrawalsRequest(params));
		} else {
			dispatch(getWithdrawalsRequest({ current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?page=1&text=${String(txt)}&field=${searchField}` : ``,
		});
		setCurrentPage(1);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 400);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);
	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
	};

	const searchHandler = (txt: string) => {
		handlePageChange(1);
		setDebouncedTerm(txt);
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Withdrawals</p>
			</div>
			<div className="count-label count-label--user-management count-label--right">
				<SearchBar
					setDebouncedTerm={searchHandler}
					debouncedTerm={debouncedTerm}
					searchFieldArray={searchFieldArray}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={searchFieldTextArray}
				/>
			</div>
			<WithdrawalList withdrawals={withdrawals?.data} handleClearSearch={handleClearSearch} />

			{(withdrawals?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={withdrawals?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default Withdrawals;
