import { IApiGetUserBalancesItem } from 'services/api/users/types';

export const dummyData: IApiGetUserBalancesItem[] = [
	{
		id: 1,
		asset: {
			id: 1,
			code: 'BTC',
			name: 'Bitcoin',
			active: 1,
			is_crypto: 1,
			icon: 'bitcoin-icon.png',
			img_path: 'images/bitcoin.png',
			settings: {
				id: 1,
				asset_id: 1,
				asset_coin_id: 1,
				deposit_max: 10,
				deposit_min: 0.001,
				withdraw_max: 5,
				withdraw_min: 0.001,
				price_decimals: 8,
				quantity_decimals: 8,
			},
		},
		balance: '0.50000000',
		frozen_balance: '0.10000000',
		total: '0.60000000',
		btc_value: '0.60000000',
		usd_value: '24000.00',
	},
	{
		id: 2,
		asset: {
			id: 2,
			code: 'ETH',
			name: 'Ethereum',
			active: 1,
			is_crypto: 1,
			icon: 'ethereum-icon.png',
			img_path: 'images/ethereum.png',
			settings: {
				id: 2,
				asset_id: 2,
				asset_coin_id: 2,
				deposit_max: 100,
				deposit_min: 0.01,
				withdraw_max: 50,
				withdraw_min: 0.01,
				price_decimals: 18,
				quantity_decimals: 18,
			},
		},
		balance: '10.000000000000000000',
		frozen_balance: '2.000000000000000000',
		total: '12.000000000000000000',
		btc_value: '0.30000000',
		usd_value: '12000.00',
	},
	{
		id: 3,
		asset: {
			id: 3,
			code: 'USD',
			name: 'US Dollar',
			active: 1,
			is_crypto: 0,
			icon: 'usd-icon.png',
			img_path: 'images/usd.png',
			settings: {
				id: 3,
				asset_id: 3,
				asset_coin_id: 3,
				deposit_max: 100000,
				deposit_min: 10,
				withdraw_max: 50000,
				withdraw_min: 10,
				price_decimals: 2,
				quantity_decimals: 2,
			},
		},
		balance: '1000.00',
		frozen_balance: '200.00',
		total: '1200.00',
		btc_value: '0.02000000',
		usd_value: '1200.00',
	},
	{
		id: 4,
		asset: {
			id: 4,
			code: 'USDT',
			name: 'Tether',
			active: 1,
			is_crypto: 1,
			icon: 'tether-icon.png',
			img_path: 'images/tether.png',
			settings: {
				id: 4,
				asset_id: 4,
				asset_coin_id: 4,
				deposit_max: 100000,
				deposit_min: 1,
				withdraw_max: 50000,
				withdraw_min: 1,
				price_decimals: 6,
				quantity_decimals: 6,
			},
		},
		balance: '5000.000000',
		frozen_balance: '1000.000000',
		total: '6000.000000',
		btc_value: '0.10000000',
		usd_value: '6000.00',
	},
	{
		id: 5,
		asset: {
			id: 5,
			code: 'LTC',
			name: 'Litecoin',
			active: 1,
			is_crypto: 1,
			icon: 'litecoin-icon.png',
			img_path: 'images/litecoin.png',
			settings: {
				id: 5,
				asset_id: 5,
				asset_coin_id: 5,
				deposit_max: 500,
				deposit_min: 0.01,
				withdraw_max: 250,
				withdraw_min: 0.01,
				price_decimals: 8,
				quantity_decimals: 8,
			},
		},
		balance: '20.00000000',
		frozen_balance: '5.00000000',
		total: '25.00000000',
		btc_value: '0.05000000',
		usd_value: '2000.00',
	},
];

export const dummyData2 = {
	data: [
		{
			id: 1,
			uuid: '123e4567-e89b-12d3-a456-426614174000',
			user_id: 1001,
			from_asset_id: 2001,
			to_asset_id: 2002,
			order_type_id: 1,
			assets_pairs_id: 3001,
			price: 50000.75,
			price_average: 49999.5,
			quantity: 1.5,
			quantity_left: 0,
			direction: 'buy',
			status: 'executed',
			created_at: 1625563200,
			updated_at: 1625566800,
			asset_pair: {
				id: 3001,
				code: 'BTC USDT',
				base_asset_id: 2001,
				quote_asset_id: 2002,
				active: 1,
				active_isolate: 0,
				shoulder_isolate: 2,
				fee_amount: 10,
				fee_amount_percent: 0.02,
				taker_fee: 0.001,
				maker_fee: 0.0005,
			},
			total: '75001.12',
			type: 'Buy',
			filled: 1.5,
		},
		{
			id: 2,
			uuid: '789e1234-e89b-12d3-a456-426614174001',
			user_id: 1002,
			from_asset_id: 2003,
			to_asset_id: 2004,
			order_type_id: 2,
			assets_pairs_id: 3002,
			price: 2500.5,
			price_average: 2501.0,
			quantity: 10,
			quantity_left: 5,
			direction: 'sell',
			status: 'opened',
			created_at: 1625567200,
			updated_at: 1625570800,
			asset_pair: {
				id: 3002,
				code: 'ETH USDT',
				base_asset_id: 2003,
				quote_asset_id: 2004,
				active: 1,
				active_isolate: 1,
				shoulder_isolate: 3,
				fee_amount: 5,
				fee_amount_percent: 0.015,
				taker_fee: 0.0009,
				maker_fee: 0.0004,
			},
			total: '25005.00',
			type: 'Sell',
			filled: 5,
		},
		{
			id: 3,
			uuid: '456e7890-e89b-12d3-a456-426614174002',
			user_id: 1003,
			from_asset_id: 2005,
			to_asset_id: 2006,
			order_type_id: 3,
			assets_pairs_id: 3003,
			price: 100.25,
			price_average: 100.1,
			quantity: 100,
			quantity_left: 0,
			direction: 'buy',
			status: 'cancelled',
			created_at: 1625571200,
			updated_at: 1625574800,
			asset_pair: {
				id: 3003,
				code: 'LTC USDT',
				base_asset_id: 2005,
				quote_asset_id: 2006,
				active: 1,
				active_isolate: 0,
				shoulder_isolate: 1,
				fee_amount: 1,
				fee_amount_percent: 0.01,
				taker_fee: 0.0008,
				maker_fee: 0.0003,
			},
			total: '10025.00',
			type: 'Buy',
			filled: 100,
		},
	],
	last_page: 2,
};
