import React, { FC } from 'react';
import { ICoinListProps } from '../../FundingWallet/types';
import TradingWalletListItem from './TradingWalletListItem';

const TradingWalletList: FC<ICoinListProps> = ({ coins }) => {
	// const userManagementListLoader = useSelector(getUsersIsLoad);
	console.log(coins, 'coins');
	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--funding-transaction">
					<div className="table-header">
						<div className="tr">
							<div className="td ">
								<div className="td-heading td-heading--header transactionRow">
									<p>Coin</p>
									<div className="accountRow">
										<svg
											width="5"
											height="3"
											viewBox="0 0 5 3"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M0 3L4.5 3H5L2.5 0.5L0 3Z" fill="#7A7A7A" />
										</svg>

										<svg
											width="8"
											height="5"
											viewBox="0 0 8 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M8 0.5H0.8H0L4 4.5L8 0.5Z" fill="#606060" />
										</svg>
									</div>
								</div>
							</div>
							<div className="td ">
								<div className="td-heading td-heading--header transactionRow">
									<p>Total</p>
									<div className="accountRow">
										<svg
											width="5"
											height="3"
											viewBox="0 0 5 3"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M0 3L4.5 3H5L2.5 0.5L0 3Z" fill="#7A7A7A" />
										</svg>

										<svg
											width="8"
											height="5"
											viewBox="0 0 8 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M8 0.5H0.8H0L4 4.5L8 0.5Z" fill="#606060" />
										</svg>
									</div>
								</div>
							</div>
							<div className="td ">
								<div className="td-heading td-heading--header transactionRow">
									<p>Available</p>
									<div className="accountRow">
										<svg
											width="5"
											height="3"
											viewBox="0 0 5 3"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M0 3L4.5 3H5L2.5 0.5L0 3Z" fill="#7A7A7A" />
										</svg>

										<svg
											width="8"
											height="5"
											viewBox="0 0 8 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M8 0.5H0.8H0L4 4.5L8 0.5Z" fill="#606060" />
										</svg>
									</div>
								</div>
							</div>
							<div className="td ">
								<div className="td-heading td-heading--header transactionRow">
									<p>In Order</p>
									<div className="accountRow">
										<svg
											width="5"
											height="3"
											viewBox="0 0 5 3"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M0 3L4.5 3H5L2.5 0.5L0 3Z" fill="#7A7A7A" />
										</svg>

										<svg
											width="8"
											height="5"
											viewBox="0 0 8 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M8 0.5H0.8H0L4 4.5L8 0.5Z" fill="#606060" />
										</svg>
									</div>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-heading td-heading--header transactionRow">
									<p>BTC Value</p>
									<div className="accountRow">
										<svg
											width="5"
											height="3"
											viewBox="0 0 5 3"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M0 3L4.5 3H5L2.5 0.5L0 3Z" fill="#7A7A7A" />
										</svg>

										<svg
											width="8"
											height="5"
											viewBox="0 0 8 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M8 0.5H0.8H0L4 4.5L8 0.5Z" fill="#606060" />
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
					{!coins?.length && (
						// ! userManagementListLoader &&
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No coins found...</p>
						</div>
					)}
					{/* {userManagementListLoader ? (
                    <div className="list-loader">
                        <Loader />
                    </div>
                ) : ( */}
					<div className="table-body">
						{coins?.map((item) => (
							<TradingWalletListItem key={item.asset_id} coins={item} />
						))}
					</div>
					{/* )} */}
				</div>
			</div>
		</div>
	);
};

export default TradingWalletList;
