/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	WHITELISTING_IP_STATUS_NOT_VERIFIED,
	WHITELISTING_IP_STATUS_WHITELISTING,
} from 'redux/reducers/userManagement/constants';
import {
	removeWhitelistIpRequest,
	verifyWhitelistIpRequest,
} from 'redux/reducers/userManagement/reducer';
import { RemoveWhitelistingIPModal } from './RemoveWhitelistingIPModal';
import { IWhitelistingIPActionsProps } from './types';
import { VerifyWhitelistingIPModal } from './VerifyWhitelistingIPModal';

export const WhitelistingIPActions: FC<IWhitelistingIPActionsProps> = ({ status, id, userId }) => {
	const dispatch = useDispatch();
	const [openRemoveModal, setOpenRemoveModal] = useState(false);
	const closeRemoveModal = () => setOpenRemoveModal(false);
	const [openVerifyModal, setOpenVerifyModal] = useState(false);
	const closeVerifyModal = () => setOpenVerifyModal(false);

	const removeWhitelistIpRequestHandler = () => {
		dispatch(removeWhitelistIpRequest({ id, userId }));
	};
	const verifyWhitelistIpRequestHandler = () => {
		dispatch(verifyWhitelistIpRequest({ id, userId }));
	};

	return (
		<>
			<div className="table-buttons table-buttons--ip">
				{status === WHITELISTING_IP_STATUS_NOT_VERIFIED ? (
					<>
						<button
							type="button"
							className="table-buttons__success-btn"
							onClick={() => setOpenVerifyModal(true)}
						>
							<svg
								width="23"
								height="23"
								viewBox="0 0 23 23"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.0163 22.4891C17.031 22.4891 22.0109 17.4985 22.0109 11.4946C22.0109 5.4799 17.0203 0.5 11.0055 0.5C5.00163 0.5 0.0217285 5.4799 0.0217285 11.4946C0.0217285 17.4985 5.01241 22.4891 11.0163 22.4891ZM11.0163 20.6568C5.92863 20.6568 1.86494 16.5823 1.86494 11.4946C1.86494 6.40691 5.91785 2.33244 11.0055 2.33244C16.0933 2.33244 20.1785 6.40691 20.1785 11.4946C20.1785 16.5823 16.104 20.6568 11.0163 20.6568Z"
									fill="#27AE60"
									stroke="none"
								/>
								<path
									d="M9.83069 16.6148C10.1864 16.6148 10.4882 16.4423 10.7038 16.1083L15.6298 8.35811C15.7484 8.14253 15.8885 7.90539 15.8885 7.66826C15.8885 7.18319 15.4573 6.87061 15.0046 6.87061C14.7351 6.87061 14.4656 7.04307 14.2609 7.35566L9.78755 14.5345L7.66407 11.7858C7.40537 11.4409 7.16824 11.3547 6.86643 11.3547C6.40293 11.3547 6.03644 11.7319 6.03644 12.2063C6.03644 12.4434 6.13345 12.6697 6.28435 12.8745L8.91444 16.1083C9.18392 16.464 9.47491 16.6148 9.83069 16.6148Z"
									fill="#27AE60"
									stroke="none"
								/>
							</svg>
						</button>
						<button
							type="button"
							className="table-buttons__delete-btn"
							onClick={() => setOpenRemoveModal(true)}
						>
							<svg
								width="22"
								height="23"
								viewBox="0 0 22 23"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.0054 22.4891C17.0202 22.4891 22 17.4985 22 11.4946C22 5.4799 17.0094 0.5 10.9947 0.5C4.99077 0.5 0.0108643 5.4799 0.0108643 11.4946C0.0108643 17.4985 5.00155 22.4891 11.0054 22.4891ZM11.0054 20.6568C5.91777 20.6568 1.85408 16.5823 1.85408 11.4946C1.85408 6.40691 5.90699 2.33244 10.9947 2.33244C16.0824 2.33244 20.1677 6.40691 20.1677 11.4946C20.1677 16.5823 16.0932 20.6568 11.0054 20.6568Z"
									fill="#EA3943"
								/>
								<path
									d="M7.31905 16.0541C7.56696 16.0541 7.78255 15.957 7.94423 15.7846L10.9947 12.7234L14.0667 15.7846C14.2284 15.9463 14.4332 16.0541 14.6811 16.0541C15.1554 16.0541 15.5434 15.666 15.5434 15.1809C15.5434 14.933 15.4572 14.739 15.2848 14.5666L12.2235 11.5052L15.2955 8.42249C15.4788 8.23925 15.5542 8.056 15.5542 7.81886C15.5542 7.34459 15.1662 6.95654 14.6919 6.95654C14.4655 6.95654 14.2823 7.04278 14.099 7.22602L10.9947 10.298L7.92267 7.2368C7.76099 7.06434 7.56696 6.9781 7.31905 6.9781C6.84478 6.9781 6.45673 7.35536 6.45673 7.82964C6.45673 8.06678 6.55374 8.27159 6.71542 8.43327L9.77668 11.5052L6.71542 14.5773C6.55374 14.739 6.45673 14.9438 6.45673 15.1809C6.45673 15.666 6.84478 16.0541 7.31905 16.0541Z"
									fill="#EA3943"
								/>
							</svg>
						</button>
					</>
				) : status === WHITELISTING_IP_STATUS_WHITELISTING ? (
					<button
						type="button"
						className="table-buttons__delete-btn"
						onClick={() => setOpenRemoveModal(true)}
					>
						<svg
							width="22"
							height="23"
							viewBox="0 0 22 23"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.0054 22.4891C17.0202 22.4891 22 17.4985 22 11.4946C22 5.4799 17.0094 0.5 10.9947 0.5C4.99077 0.5 0.0108643 5.4799 0.0108643 11.4946C0.0108643 17.4985 5.00155 22.4891 11.0054 22.4891ZM11.0054 20.6568C5.91777 20.6568 1.85408 16.5823 1.85408 11.4946C1.85408 6.40691 5.90699 2.33244 10.9947 2.33244C16.0824 2.33244 20.1677 6.40691 20.1677 11.4946C20.1677 16.5823 16.0932 20.6568 11.0054 20.6568Z"
								fill="#EA3943"
							/>
							<path
								d="M7.31905 16.0541C7.56696 16.0541 7.78255 15.957 7.94423 15.7846L10.9947 12.7234L14.0667 15.7846C14.2284 15.9463 14.4332 16.0541 14.6811 16.0541C15.1554 16.0541 15.5434 15.666 15.5434 15.1809C15.5434 14.933 15.4572 14.739 15.2848 14.5666L12.2235 11.5052L15.2955 8.42249C15.4788 8.23925 15.5542 8.056 15.5542 7.81886C15.5542 7.34459 15.1662 6.95654 14.6919 6.95654C14.4655 6.95654 14.2823 7.04278 14.099 7.22602L10.9947 10.298L7.92267 7.2368C7.76099 7.06434 7.56696 6.9781 7.31905 6.9781C6.84478 6.9781 6.45673 7.35536 6.45673 7.82964C6.45673 8.06678 6.55374 8.27159 6.71542 8.43327L9.77668 11.5052L6.71542 14.5773C6.55374 14.739 6.45673 14.9438 6.45673 15.1809C6.45673 15.666 6.84478 16.0541 7.31905 16.0541Z"
								fill="#EA3943"
							/>
						</svg>
					</button>
				) : null}
			</div>
			<RemoveWhitelistingIPModal
				openModal={openRemoveModal}
				closeModal={closeRemoveModal}
				handleRemoveWhitelistingIP={removeWhitelistIpRequestHandler}
			/>
			<VerifyWhitelistingIPModal
				openModal={openVerifyModal}
				closeModal={closeVerifyModal}
				handleVerifyWhitelistingIP={verifyWhitelistIpRequestHandler}
			/>
		</>
	);
};
