import { FC } from 'react';
import Popup from 'reactjs-popup';
import { IAcceptWithdrawalsModalProps } from './types';
import tick from '../../../../../../assets/img/modaltick.svg';

// ================================================:
export const AcceptWithdrawalsModal: FC<IAcceptWithdrawalsModalProps> = ({
	openModal,
	closeModal,
	handleRejectWithdrawal,
}) => {
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window admin-managment-popup">
				<div className="popup-window__inside">
					<div className="popup">
						<button onClick={closeModal} type="button" className="popup__close">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#011E26" />
							</svg>
						</button>
						<div className="popup-header">
							<img src={tick} className="popup-image" alt="" />
							<h3 className="popup-header__title">Accept withdrawal</h3>
						</div>
						<div className="popup-body">
							<div className="popup-text popup-text--center">
								<p>You really want to confirm </p>
								<p>this withdrawals?</p>
							</div>

							{/* <div className="popup-submit popup-submit--sb ">
								<button onClick={closeModal} type="button" className="button button--wider">
									Cancel
								</button>
								<button
									className="button button--wider button--full button--del"
									type="button"
									onClick={() => {
										closeModal();
										handleRejectWithdrawal();
									}}
								>
									Accept
								</button>
							</div> */}

							<div className="popup-submit popup-submit--sb ">
								<div>
									<button
										onClick={closeModal}
										type="button"
										className="button button--full button--cancel"
									>
										Cancel
									</button>
								</div>
								<div>
									<button
										className="button  button--full button--del"
										type="button"
										onClick={() => {
											closeModal();
											handleRejectWithdrawal();
										}}
									>
										Confirm Withdrawals
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
