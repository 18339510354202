import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeTradeFeesAndLimitsRequest } from 'redux/reducers/fees/reducer';
import { noExponent, numberFormat8Fraction } from 'utils/numberFormat';
import { IChangeTradeFeesAndLimitsItemProps } from './types';

const TradeFeesAndLimitsItem: FC<IChangeTradeFeesAndLimitsItemProps> = ({ item }) => {
	const [takerFee, setTakerFee] = useState(String(item.taker_fee));
	const [makerFee, setMakerFee] = useState(String(item.maker_fee));
	const [amountMin, setAmountMin] = useState(String(item.amount_min));
	const [amountMax, setAmountMax] = useState(String(item.amount_max));
	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();

	const handleTradeFeesAndLimitsSubmit = () => {
		if (makerFee === '' || takerFee === '' || amountMin === '' || amountMax === '') return;
		dispatch(
			changeTradeFeesAndLimitsRequest({
				asset_pair_id: item.id,
				maker_fee: Number(makerFee),
				taker_fee: Number(takerFee),
				amount_min: Number(amountMin),
				amount_max: Number(amountMax),
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p>{item.code.split('_').join('/').toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Taker Fee, %</p>
				{isClosed ? <div /> : <p>{takerFee}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={takerFee}
						onChange={(e) => {
							setTakerFee(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Maker Fee, %</p>
				{isClosed ? <div /> : <p>{makerFee}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={makerFee}
						onChange={(e) => {
							setMakerFee(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount Min</p>
				{isClosed ? <div /> : <p>{numberFormat8Fraction(Number(amountMin), 'en-En')}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={noExponent(amountMin)}
						onChange={(e) => {
							setAmountMin(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount Max</p>
				{isClosed ? <div /> : <p>{numberFormat8Fraction(Number(amountMax), 'en-En')}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={amountMax}
						onChange={(e) => {
							setAmountMax(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="button"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setTakerFee(String(item.taker_fee));
							setMakerFee(String(item.maker_fee));
							setAmountMin(String(item.amount_min));
							setAmountMax(String(item.amount_max));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleTradeFeesAndLimitsSubmit();
							}}
							className={
								makerFee === '' || takerFee === '' || amountMin === '' || amountMax === ''
									? 'button-not-active'
									: ''
							}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="submit" onClick={() => setIsClosed(!isClosed)}>
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.0058 24.4882C18.5673 24.4882 23.9999 19.0439 23.9999 12.4941C23.9999 5.93262 18.5556 0.5 11.9941 0.5C5.44434 0.5 0.0117188 5.93262 0.0117188 12.4941C0.0117188 19.0439 5.4561 24.4882 12.0058 24.4882ZM12.0058 22.4892C6.45562 22.4892 2.0225 18.0443 2.0225 12.4941C2.0225 6.9439 6.44386 2.49902 11.9941 2.49902C17.5443 2.49902 22.001 6.9439 22.001 12.4941C22.001 18.0443 17.5561 22.4892 12.0058 22.4892Z"
									fill="white"
								/>
								<path
									d="M8.23125 17.5858L15.9451 9.89552L14.5576 8.50796L6.85544 16.1865L6.18518 17.7505C6.0676 18.0445 6.34982 18.2914 6.60851 18.1973L8.23125 17.5858ZM16.6506 9.20174L17.4973 8.34334C17.8736 7.94354 17.9088 7.52021 17.5561 7.16744L17.2856 6.89699C16.9329 6.54423 16.4978 6.5795 16.1215 6.95578L15.2631 7.80242L16.6506 9.20174Z"
									fill="white"
								/>
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default TradeFeesAndLimitsItem;
