/* eslint-disable no-nested-ternary */
import { FC } from 'react';

interface TableDataProps {
	data: {
		typeUser?: string;
		lastYear?: string;
		lastQuater?: string;
		lastMonth?: string;
		lastWeek?: string;
		lastDay?: string;
		// Add more properties as needed
	};
}
const TableData: FC<TableDataProps> = ({ data }) => {
	const textValid = (txt?: string | null) => {
		const txtTrim = txt?.trim();
		if (txtTrim) return txtTrim;
		return '-';
	};

	return (
		<div className="tr">
			<div className="td ">
				{data.typeUser ? (
					<div>
						<span className="">{data.typeUser}</span>
					</div>
				) : (
					<div>
						<span className="">{data.lastYear}</span>
					</div>
				)}
			</div>

			<div className="td ">
				<div>
					<span className="">{data.lastQuater}</span>
				</div>
			</div>
			<div className="td  ">
				<div>
					<span className="">{data.lastMonth}</span>
				</div>
			</div>
			<div className="td  ">
				<div>
					<span className="">{data.lastWeek}</span>
				</div>
			</div>
			<div className="td  ">
				<div>
					<span className="">{data.lastDay}</span>
				</div>
			</div>
		</div>
	);
};

export default TableData;
