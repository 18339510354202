import React, { FC, useState } from 'react';

import Pagination from 'ui/Pagination';
import queryString from 'query-string';
import 'rsuite/dist/rsuite.min.css';
import { useLocation } from 'react-router';
import List from './WithdrawalLimitList';
import withdrawlFeesList from '../Transactions/Transfers/dummy.json';

const Withdrawllimits: FC = () => {
	const { search } = useLocation();
	const { page, text } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 2);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams = `?page=${String(pageNumber)}`;
	};

	return (
		<>
			<div className="trading-pairs">
				<div className="title-block title-block--admin-managment">
					<p className="title">Withdrawal Limits</p>
				</div>

				<div className="trading-pairs-body">
					<List />
				</div>

				{/* <AddNewPairModal openModal={openModal} setCloseModal={() => setOpenModal(false)} /> */}
			</div>
			{(withdrawlFeesList.length ?? 0) > 1 && (
				<Pagination
					pageCount={1}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
					total={1}
				/>
			)}
		</>
	);
};

export default Withdrawllimits;
