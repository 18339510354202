export const dummyData = [
	{
		id: 5,
		user_id: 1005,
		pair: 'BTC/USDT',
		pair_name: 'Bitcoin',
		value: 0.4,
		fee: 0.4,
		status: 'Confirmed',
		created: '2024-05-23',
	},
	{
		id: 6,
		user_id: 1006,
		pair: 'BTC/USDT',
		pair_name: 'Bitcoin',
		value: 0.4,
		fee: 0.4,
		status: 'Unconfirmed',
		created: '2024-05-22',
	},
];
