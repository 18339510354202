import SearchBar from 'components/SearchBar';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminPermissions } from 'redux/reducers/auth/selectors';
import { getTradingPairsRequest } from 'redux/reducers/tradingPairs/reducer';
import {
	getAddAssetsPairLoading,
	getEditTradingPairsLoading,
	getTradingPairs,
} from 'redux/reducers/tradingPairs/selectors';
import {
	TRADING_SEARCH_ARRAY,
	TRADING_SEARCH_TEXT_ARRAY,
} from 'redux/reducers/userManagement/constants';
import Loader from 'ui/Loader';
import { TradingPairsItem } from './TradingPairsItem';

export const SpotPairs: FC = () => {
	const dispatch = useDispatch();
	const tradingPairsState = useSelector(getTradingPairs);
	const editLoading = useSelector(getEditTradingPairsLoading);
	const addAssetsPairLoading = useSelector(getAddAssetsPairLoading);
	const permissions = useSelector(getAdminPermissions);
	const searchField = 'pair';
	const [pairs, setPairs] = useState(tradingPairsState.tradingPairs);

	const manageDerivatives =
		permissions?.find((permission: any) => permission.name === 'manage derivatives') || null;

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 500);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		if (term) {
			const results = tradingPairsState.tradingPairs?.filter((pair) =>
				pair.code.toLowerCase().includes(term.toLowerCase()),
			);
			setPairs(results || []);
		} else {
			setPairs(tradingPairsState.tradingPairs);
		}
	}, [term, tradingPairsState.tradingPairs]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	useEffect(() => {
		if (!editLoading && !addAssetsPairLoading) {
			dispatch(getTradingPairsRequest());
		}
	}, [editLoading, addAssetsPairLoading, dispatch]);

	return (
		<>
			<div className="count-label--user-management count-label--topMargin mb-3">
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={TRADING_SEARCH_ARRAY}
					searchField={searchField}
					setSearchField={() => {}}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={TRADING_SEARCH_TEXT_ARRAY}
					hideDropDown
				/>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--trading-pairs">
						<div className="table-header">
							<div className="tr">
								<div className="td td--status">
									<div className="td-name">
										<p>Pair</p>
									</div>
								</div>

								<div className="td td--status">
									<div className="td-name">
										<p>User Orders</p>
									</div>
								</div>

								<div className="td td--status">
									<div className="td-name">
										<p>Total Number of Orders</p>
									</div>
								</div>

								<div className="td td--status">
									<div className="td-name">
										<p>Decimal</p>
									</div>
								</div>

								<div className="td td--status">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Edit</p>
									</div>
								</div>
							</div>
						</div>

						<div className="table-body">
							{!tradingPairsState?.tradingPairs?.length && !tradingPairsState?.tradingPairsLoader && (
								<div className="user-management-empty-data">
									<p className="user-management-empty__text">No trading pairs found...</p>
								</div>
							)}

							{tradingPairsState?.tradingPairsLoader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								<>
									{tradingPairsState.editTradingPairsLoader ? (
										<div className="list-loader-update">
											<Loader />
										</div>
									) : null}
									<div className={tradingPairsState.editTradingPairsLoader ? 'updating' : ''}>
										{pairs?.map((item: any) => (
											<TradingPairsItem
												perpetualEdit={Boolean(manageDerivatives)}
												data={item}
												key={item.code}
											/>
										))}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
