export const data = {
	all_notifications: {
		notifications: [
			{
				id: 1,
				sms_enabled: false,
				email_enabled: true,
				name: 'Deposit Completed',
			},
			{
				id: 2,
				sms_enabled: false,
				email_enabled: false,
				name: 'Withdrawl Completed',
			},
			{
				id: 3,
				sms_enabled: false,
				email_enabled: true,
				name: 'Limit Order has been Filed',
			},
		],
		total: 3,
		last_page: 10,
	},
	notificationsLoader: false,
};
