/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useState, useEffect } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, Text } from 'recharts';
import CustomTooltip from './CustomTooltip';
import RenderActiveShape from './ActiveShape';
import { IBalanceChartProps, IChartData } from './types';

const COLORS = ['#27AE60', '#F7931A', '#DC2828', '#6B252A', '#44301A', '#1D5336'];

const Chart: FC<IBalanceChartProps> = ({ data, currentBalanceItemName }) => {
	const [activeBalanceItemIndex, setActiveBalanceItemIndex] = useState(0);

	useEffect(() => {
		const currentItemIndex = data?.findIndex((el) => el.asset_code === currentBalanceItemName);

		setActiveBalanceItemIndex(currentItemIndex);
	}, [data, currentBalanceItemName]);

	const pieChartData: IChartData[] = [];

	data?.forEach((item) => {
		pieChartData.push({
			name: item.asset_code,
			value: Number(
				item.balances.reduce((sum: number, elem: any) => {
					return sum + Number(elem.balance_usdt);
				}, 0),
			),
		});
	});

	return (
		<div className="container">
			<div className="row">
				<div className=" col-12">
					<ResponsiveContainer width={305} height={300}>
						<PieChart>
							<Pie
								data={pieChartData}
								cx="50%"
								cy="50%"
								activeIndex={activeBalanceItemIndex}
								activeShape={(props) => <RenderActiveShape data={data} {...props} />}
								outerRadius={100}
								innerRadius={50}
								fill="#8884d8"
								dataKey="value"
								paddingAngle={3}
								startAngle={30}
								endAngle={400}
								minAngle={3}
								stroke="none"
							>
								{pieChartData?.map((entry: IChartData, index: number) => (
									<Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
								))}
							</Pie>

							<Tooltip content={(props) => <CustomTooltip {...props} data={data} />} />
						</PieChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
};

export default Chart;
