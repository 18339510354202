/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editTradingPairsRequest } from 'redux/reducers/tradingPairs/reducer';
import Switcher from 'components/Switcher';
import { ITradingPairsItemProps } from './types';

export const TradingPairsItem: FC<ITradingPairsItemProps> = ({ data, perpetualEdit }) => {
	const [switcher, setSwitcher] = useState(Boolean(data.active));
	const [decimal, setDecimal] = useState(String(data.view_decimal));
	const [isRedact, setIsRedact] = useState(false);
	const [notCorrect, setNotCorrect] = useState(false);

	const dispatch = useDispatch();

	const handleTradingPairsChange = () => {
		if (notCorrect) return;
		dispatch(
			editTradingPairsRequest({
				code: data.code,
				view_decimal: Number(decimal),
				active: switcher ? 1 : 0,
			}),
		);
	};

	const changeSwitcherVal = (val: boolean) => {
		dispatch(
			editTradingPairsRequest({
				code: data.code,
				view_decimal: Number(decimal),
				active: val ? 1 : 0,
			}),
		);
		setSwitcher(val);
	};

	return (
		<div className="tr">
			<div className="td td--status">
				<p className="td-hidden-name">Pair</p>
				<p>{data.code.toUpperCase().replace('_', '/')}</p>
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">User Orders</p>
				<p>{data.user_orders_count}</p>
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Total Number of Orders</p>
				<p>{data.user_orders_count}</p>
			</div>

			<div className="td td--status">
				<p className="td-hidden-name">Decimal</p>
				{!isRedact ? (
					<p>{decimal}</p>
				) : (
					<div className=" td--status table-input">
						<input
							type="text"
							value={decimal}
							onChange={(e) => {
								setDecimal(e.target.value.replace(/[^\d]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Action</p>
				<Switcher setIsActive={changeSwitcherVal} isActive={switcher} />
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					{isRedact ? (
						<>
							<button
								type="submit"
								onClick={() => {
									setDecimal(String(data.view_decimal));
									setIsRedact(!isRedact);
								}}
							>
								<span className="edit-icon icon-cancel-icon" />
							</button>

							<button
								type="button"
								onClick={handleTradingPairsChange}
								className={notCorrect ? 'button-not-active' : ''}
							>
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
										stroke="#00DA83"
										strokeLinecap="square"
									/>
								</svg>
							</button>
						</>
					) : (
						<button type="button" onClick={() => setIsRedact(!isRedact)}>
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.0058 24.4882C18.5673 24.4882 23.9999 19.0439 23.9999 12.4941C23.9999 5.93262 18.5556 0.5 11.9941 0.5C5.44434 0.5 0.0117188 5.93262 0.0117188 12.4941C0.0117188 19.0439 5.4561 24.4882 12.0058 24.4882ZM12.0058 22.4892C6.45562 22.4892 2.0225 18.0443 2.0225 12.4941C2.0225 6.9439 6.44386 2.49902 11.9941 2.49902C17.5443 2.49902 22.001 6.9439 22.001 12.4941C22.001 18.0443 17.5561 22.4892 12.0058 22.4892Z"
									fill="white"
								/>
								<path
									d="M8.23125 17.5858L15.9451 9.89552L14.5576 8.50796L6.85544 16.1865L6.18518 17.7505C6.0676 18.0445 6.34982 18.2914 6.60851 18.1973L8.23125 17.5858ZM16.6506 9.20174L17.4973 8.34334C17.8736 7.94354 17.9088 7.52021 17.5561 7.16744L17.2856 6.89699C16.9329 6.54423 16.4978 6.5795 16.1215 6.95578L15.2631 7.80242L16.6506 9.20174Z"
									fill="white"
								/>
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
