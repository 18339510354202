import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { getAdminPermissions } from 'redux/reducers/auth/selectors';

interface NavProps {
	handleMobileMenu: () => void;
}
const Nav: FC<NavProps> = ({ handleMobileMenu }) => {
	const isUserManagementPathname = (pathname: string): boolean =>
		pathname === '/user-management' || pathname === '/2FA';
	const location = useLocation();
	const [open, setOpen] = useState<boolean>(isUserManagementPathname(location.pathname));
	const permissions = useSelector(getAdminPermissions);
	// const manageAdmins =
	// 	permissions?.find((permission: any) => permission.name === 'manage admins') || null;
	const manageUsers =
		permissions?.find((permission: any) => permission.name === 'manage users') || null;
	const manageGeneral =
		permissions?.find((permission: any) => permission.name === 'manage general') || null;
	// const manageTwoFa =
	// 	permissions?.find((permission: any) => permission.name === 'fa reset request') || null;
	// const manageChangePassword =
	// 	permissions?.find((permission: any) => permission.name === 'change password"') || null;
	const managePendingWithdrawals =
		permissions?.find((permission: any) => permission.name === 'manage pending withdrawals') ||
		null;
	// const manageViewOrders =
	// 	permissions?.find((permission: any) => permission.name === 'view orders') || null;
	// const manageCurrencies =
	// 	permissions?.find((permission: any) => permission.name === 'manage currencies') || null;
	// const manageViewTrades =
	// 	permissions?.find((permission: any) => permission.name === 'view trades') || null;
	const manageAssetPairs =
		permissions?.find((permission: any) => permission.name === 'manage asset pairs') || null;
	const manageViewTransactions =
		permissions?.find((permission: any) => permission.name === 'view transactions') || null;
	const manageFees =
		permissions?.find((permission: any) => permission.name === 'manage fees') || null;
	const manageWallets =
		permissions?.find((permission: any) => permission.name === 'manage wallets') || null;
	// const manageLimits =
	// 	permissions?.find((permission: any) => permission.name === 'manage limits') || null;
	// const manageDerivatives =
	// 	permissions?.find((permission: any) => permission.name === 'manage derivatives') || null;
	// const manageOptions =
	// 	permissions?.find((permission: any) => permission.name === 'manage options') || null;
	// const manageMarketMaker =
	// 	permissions?.find((permission: any) => permission.name === 'manage market maker') || null;

	useEffect(() => {
		if (isUserManagementPathname(location.pathname)) {
			setOpen(true);
		}
	}, [location.pathname]);
	const handleLinkClick = () => {
		handleMobileMenu();
	};

	return (
		<nav className="sidebar-nav">
			<ul className="sidebar-nav__list sidebar-nav__list-custom">
				<li className="category">General</li>
				{manageGeneral && (
					<li>
						<NavLink to="/" activeClassName="active" exact onClick={handleLinkClick}>
							Dashboard
						</NavLink>
					</li>
				)}
				{manageUsers && (
					<li>
						<NavLink to="/user-management" activeClassName="active" exact onClick={handleLinkClick}>
							Users Management
						</NavLink>
					</li>
				)}
				<li className="category">Finances</li>
				{manageFees && (
					<li>
						<NavLink to="/fee-management" activeClassName="active" exact onClick={handleLinkClick}>
							Fee Management
						</NavLink>
					</li>
				)}
				{/* {manageTwoFa && open && (
					<li>
						<NavLink
							to="/2FA"
							className="sidebar-nav__subitem-custom"
							activeClassName="active"
							exact
						>
							2FA reset request
						</NavLink>
					</li>
				)} */}
				{/* {managePendingWithdrawals && (
					<li>
						<NavLink to="/pending-withdrawals" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-withdrawals-icon" /> Pending
							Withdrawals
						</NavLink>
					</li>
				)}  */}
				{managePendingWithdrawals && (
					<li>
						<NavLink
							to="/pending-withdrawals"
							activeClassName="active"
							exact
							onClick={handleLinkClick}
						>
							Withdrawals
						</NavLink>
					</li>
				)}
				{/* {manageViewOrders && (
					<li>
						<NavLink to="/orders" activeClassName="active" exact>
							<span className="sidebar-nav__icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.625 14.25H9.375C12.5 14.25 13.75 13 13.75 9.875V6.125C13.75 3 12.5 1.75 9.375 1.75H5.625C2.5 1.75 1.25 3 1.25 6.125V9.875C1.25 13 2.5 14.25 5.625 14.25Z"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path d="M9.84375 6.125H5.15625" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M9.84375 9.875H5.15625" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</span>
							Orders
						</NavLink>
					</li>
				)} */}
				{/* {manageCurrencies && (
					<li>
						<NavLink to="/currencies" activeClassName="active" exact>
							<span className="sidebar-nav__icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.625 14.25H9.375C12.5 14.25 13.75 13 13.75 9.875V6.125C13.75 3 12.5 1.75 9.375 1.75H5.625C2.5 1.75 1.25 3 1.25 6.125V9.875C1.25 13 2.5 14.25 5.625 14.25Z"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path d="M9.84375 6.125H5.15625" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M9.84375 9.875H5.15625" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</span>
							Currencies
						</NavLink>
					</li>
				)} */}
				{/* {manageViewTrades && (
					<li>
						<NavLink to="/trades" activeClassName="active" exact>
							<span className="sidebar-nav__icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.31874 7.46875H4.66249C4.26874 7.46875 3.95 7.78747 3.95 8.18122V11.3812H6.31874V7.46875V7.46875Z"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7.97579 4.625H7.02578C6.63203 4.625 6.31329 4.94376 6.31329 5.33751V11.375H8.68204V5.33751C8.68204 4.94376 8.36954 4.625 7.97579 4.625Z"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10.3426 8.53125H8.68633V11.375H11.0551V9.24376C11.0488 8.85001 10.7301 8.53125 10.3426 8.53125Z"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.625 14.25H9.375C12.5 14.25 13.75 13 13.75 9.875V6.125C13.75 3 12.5 1.75 9.375 1.75H5.625C2.5 1.75 1.25 3 1.25 6.125V9.875C1.25 13 2.5 14.25 5.625 14.25Z"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							Trades
						</NavLink>
					</li>
				)} */}
				{manageAssetPairs && (
					<li>
						<NavLink to="/trading-pairs" activeClassName="active" exact onClick={handleLinkClick}>
							Trading Pairs
						</NavLink>
					</li>
				)}
				<li>
					<NavLink to="/withdral-limit" activeClassName="active" exact onClick={handleLinkClick}>
						Withdrawal limits
					</NavLink>
				</li>
				{/* <li>
					<NavLink
						to="/financial-analytics"
						activeClassName="active"
						exact
						onClick={handleLinkClick}
					>
						Financial Analytics
					</NavLink>
				</li> */}
				{manageViewTransactions && (
					<li>
						<NavLink to="/transactions" activeClassName="active" exact onClick={handleLinkClick}>
							Transactions
						</NavLink>
					</li>
				)}
				{/* {manageFees && (
					<li>
						<NavLink to="/fee-management" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-fee-management-icon" /> Fee
							Management
						</NavLink>
					</li>
				)} */}
				{manageWallets && (
					<li>
						<NavLink
							to="/wallet-management"
							activeClassName="active"
							exact
							onClick={handleLinkClick}
						>
							Wallet Management
						</NavLink>
					</li>
				)}
				<li className="category">More</li>
				<li>
					<NavLink to="/notification" activeClassName="active" exact onClick={handleLinkClick}>
						Notification
					</NavLink>
				</li>
				{/* <li>
					<NavLink to="/announcements" activeClassName="active" exact onClick={handleLinkClick}>
						Announcements
					</NavLink>
				</li> */}
				{/* <li>
					<NavLink to="/settings" activeClassName="active" exact onClick={handleLinkClick}>
						Settings
					</NavLink>
				</li> */}
				{/* {manageAdmins && (
					<li>
						<NavLink to="/admin-management" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-admin-managment-icon" /> Admin
							Management
						</NavLink>
					</li>
				)} */}
				{/* HIDE NON-WORKING */}
				{/* <li>
					<NavLink to="/dispute" activeClassName="active" exact>
						<span className="sidebar-nav__icon sidebar-icon icon-ipWhitelisting-icon" />
						Dispute
					</NavLink>
				</li> */}
				{/* <li> */}
				{/*	<NavLink to="/ip-whitelisting" activeClassName="active" exact> */}
				{/*		<span className="sidebar-nav__icon sidebar-icon icon-ipWhitelisting-icon" /> IP & */}
				{/*		Whitelisting */}
				{/*	</NavLink> */}
				{/* </li> */}
				{/* {manageLimits && (
					<li>
						<NavLink to="/limitations" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-limitations-icon" /> Limitations
						</NavLink>
					</li>
				)} */}
				{/* HIDE NON-WORKING */}
				{/* <li>
					<NavLink to="/notification" activeClassName="active" exact>
						<span className="sidebar-nav__icon sidebar-icon icon-notification-icon" /> Notification
					</NavLink>
				</li> */}
				{/* HIDE NON-WORKING */}
				{/* <li>
					<NavLink to="/market-maker-bot" activeClassName="active" exact className="market-robot">
						<span className="sidebar-nav__icon sidebar-icon robot-icon">
							<svg
								width="15"
								height="16"
								viewBox="0 0 15 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M7.5 3C10.2614 3 12.5 5.23858 12.5 8V14C12.5 14.5523 12.0523 15 11.5 15H3.5C2.94772 15 2.5 14.5523 2.5 14V8C2.5 5.23858 4.73858 3 7.5 3ZM7.5 3V0.5M4 12H11M0.5 8.5V12.5M14.5 8.5V12.5M5.5 10C4.94772 10 4.5 9.55228 4.5 9C4.5 8.44772 4.94772 8 5.5 8C6.05228 8 6.5 8.44772 6.5 9C6.5 9.55228 6.05228 10 5.5 10ZM9.5 10C8.94772 10 8.5 9.55228 8.5 9C8.5 8.44772 8.94772 8 9.5 8C10.0523 8 10.5 8.44772 10.5 9C10.5 9.55228 10.0523 10 9.5 10Z" />
							</svg>
						</span>
						Market-Maker Bot
					</NavLink>
				</li> */}
				{/* {manageChangePassword && (
					<li>
						<NavLink to="/settings" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-settings-icon" /> Settings
						</NavLink>
					</li>
				)} */}
			</ul>
		</nav>
	);
};

export default Nav;
