import React from 'react';
import { dummyData } from '../FundingWallet/constants';
import TradingWalletList from './TradingWalletList';

const TradingWallet = () => {
	const coins = dummyData;
	console.log(dummyData, 'dummy data');
	return (
		<div>
			<TradingWalletList coins={coins} />
		</div>
	);
};

export default TradingWallet;
