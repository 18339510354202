import React, { FC } from 'react';
import { ICoinListItemProps } from './types';

const FundingWalletListItem: FC<ICoinListItemProps> = ({ coins }) => {
	return (
		<div className="tr">
			<div className="td">
				<div className="imageFlexsmall">
					<p className="td-hidden-name">Coin</p>
					<img
						className="imageFlexsmall imageFlexsmall--code"
						style={{ height: '24px', width: '24px' }}
						src={coins?.asset_logo_url}
						alt="img"
						height="24px"
						width="24px"
					/>

					<div className="td-name td-name--wallet">
						<p className="imageFlexsmall imageFlexsmall--code">{coins?.asset_code}</p>
						<p className="imageFlexsmall imageFlexsmall--name">{coins?.asset_name}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<p className="td-name td-name--BTCValue">{coins?.total}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Available</p>
				<p className="td-name td-name--BTCValue">{coins?.available}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">BTC Value</p>
				<p className="td-name td-name--BTCValue">{coins?.btc_value}</p>
				<p className="td-name td-name--wallet">≈{coins?.btc_equivalent}</p>
			</div>
		</div>
	);
};

export default FundingWalletListItem;
